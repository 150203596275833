import React, { useState } from "react";

type Props = {
  message: any;
  user: any;
};

const SentMessage: React.VFC<Props> = (props) => {
  console.log("props", props);
  return (
    <>
      <div className="direct-chat-msg right mb-3">
        <div className="direct-chat-infos clearfix">
          <span className="direct-chat-name float-right">
            {props.user.name}
          </span>
          <span className="direct-chat-timestamp float-left">
            {props.message.elapsed}
          </span>
        </div>

        <img
          className="direct-chat-img"
          src={props.user.avatar_image.url}
          alt={props.user.name}
        />

        <div className="direct-chat-text p-3 sent">{props.message.body}</div>
      </div>
    </>
  );
};
export default SentMessage;
