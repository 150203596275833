import React, { useState } from "react";

type Props = {
  message: any;
  user: any;
  clinicIcon: String;
};

const RecievedMessage: React.VFC<Props> = (props) => {
  return (
    <>
      <div className="direct-chat-msg mb-3">
        <div className="direct-chat-infos clearfix">
          <span className="direct-chat-name float-left">クリニック</span>
          <span className="direct-chat-timestamp float-right">
            {props.message.elapsed}
          </span>
        </div>

        <img
          className="direct-chat-img"
          src={props.clinicIcon}
          alt="クリニック"
        />

        <div className="direct-chat-text p-3 recieved">
          {props.message.body}
        </div>
      </div>
    </>
  );
};
export default RecievedMessage;
