import React, { useState, useEffect } from "react";
import UserLists from "./UserLists";
import Chat from "./Chat";
import Bar from "./Bar";
import consumer from "../../channels/consumer";
import { Toaster } from "react-hot-toast";
import { searchLineMessageUsers } from "../../libs/api/user";
import {
  ActionCableProvider,
  ActionCableConsumer,
} from "react-actioncable-provider";

type Props = {
  users: [any];
  uid: String;
  ws_host: String;
  current_line_message_count: Number;
  line_message_limit: Number;
  clinic_icon: String;
};

const LineMessages: React.VFC<Props> = (props) => {
  console.log("props", props);
  const [users, setUsers] = useState(props.users);
  const [chatUser, setChatUser] = useState(null);
  const [channel, setChannel] = useState("");
  const [searchingUser, setSearchingUser] = useState(false);
  const [currentLineMessageCount, setCurrentLineMessageCount] = useState(
    props.current_line_message_count
  );
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    //チャンネル作成
    const LineMessageChannel = consumer.subscriptions.create(
      {
        channel: "LineMessageChannel",
        uid: props.uid,
      },
      {
        send_message(message, targetUserId, clinicId) {
          this.perform("send_message", {
            message: message,
            target_user_id: targetUserId,
            clinic_id: clinicId,
          });
        },
      }
    );
    setChannel(LineMessageChannel);
  }, [props.users]);

  const handleReceived = (line_message) => {
    if (chatUser.id == line_message.user_id) {
      setMessages((prevMessages) => [...prevMessages, line_message]);
    }
    if (line_message.message_type == "sent") {
      setCurrentLineMessageCount((prevCount) => prevCount + 1);
    }
    setUsers((prevUsers) => {
      const filteredUsers = prevUsers.filter(
        (user) => user.id != line_message.user_id
      );
      const targetUser = prevUsers.find(
        (user) => user.id == line_message.user_id
      );
      let unread_count;
      if (chatUser.id == line_message.user_id) {
        unread_count = 0;
      } else {
        unread_count = line_message.unread_count;
      }
      return [
        {
          id: targetUser.id,
          name: targetUser.name,
          avatar_image: targetUser.avatar_image,
          message_elapsed: line_message.elapsed,
          unread_count: unread_count,
          last_message: line_message.body,
        },
        ...filteredUsers,
      ];
    });
  };

  const updateUserLists = (newUser) => {
    setUsers((prevUsers) => {
      return prevUsers.map((prevUser) => {
        if (prevUser.id == newUser.id) {
          return newUser;
        } else {
          return prevUser;
        }
      });
    });
  };

  const searchUser = async (name) => {
    setTimeout(async () => {
      if (!searchingUser && name.length > 1) {
        setSearchingUser(true);
        //console.log("todo api search user", name);
        const res = await searchLineMessageUsers(name);
        setUsers(res.data.users);
        setSearchingUser(false);
      } else {
        setUsers(props.users);
      }
    }, 100);
  };

  return (
    <>
      <ActionCableProvider cable={consumer}>
        <ActionCableConsumer
          channel={{ channel: "LineMessageChannel", uid: props.uid }}
          onReceived={handleReceived}
        />
        <div className="line-message-wrap d-flex">
          <div className="user-lists bg-white border-right position-relative">
            <div className="position-fixed border-right bg-white d-flex border-bottom p-3 line-message-search-user">
              <input
                className="form-control form-control-sm"
                type="text"
                placeholder="名前で検索"
                onChange={(e) => {
                  searchUser(e.target.value);
                }}
              />
            </div>
            {searchingUser ? (
              <>
                <div className="text-center p-3 user-lists-wrap">
                  検索中です...
                </div>
              </>
            ) : (
              <>
                <UserLists
                  users={users}
                  setUsers={setUsers}
                  setChatUser={setChatUser}
                ></UserLists>
              </>
            )}
          </div>
          <div className="chat">
            <div className="pt-3 pl-3 pr-3">
              <div className="card mb-0">
                <div className="card-header p-2">メッセージ送信上限</div>
                <div className="card-body p-2">
                  <Bar
                    currentLineMessageCount={currentLineMessageCount}
                    lineMessageLimit={props.line_message_limit}
                  ></Bar>
                </div>
              </div>
            </div>
            {chatUser != null && (
              <Chat
                chatUser={chatUser}
                setChatUser={setChatUser}
                channel={channel}
                uid={props.uid}
                clinicId={props.clinic_id}
                setUsers={setUsers}
                currentLineMessageCount={currentLineMessageCount}
                lineMessageLimit={props.line_message_limit}
                setCurrentLineMessageCount={setCurrentLineMessageCount}
                messages={messages}
                setMessages={setMessages}
                updateUserLists={updateUserLists}
                clinicIcon={props.clinic_icon}
              ></Chat>
            )}
          </div>
        </div>
      </ActionCableProvider>
      <Toaster />
    </>
  );
};
export default LineMessages;
