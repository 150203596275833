import React, { useState } from "react";

type Props = {
  message: any;
  user: any;
  clinicIcon: String;
};

import RecievedMessage from "./RecievedMessage";
import SentMessage from "./SentMessage";

const Message: React.VFC<Props> = (props) => {
  if (props.message.message_type == "sent") {
    return (
      <RecievedMessage
        message={props.message}
        clinicIcon={props.clinicIcon}
      ></RecievedMessage>
    );
  } else if (props.message.message_type == "recieved") {
    return (
      <SentMessage message={props.message} user={props.user}></SentMessage>
    );
  }
};
export default Message;
