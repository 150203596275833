import React, { useState } from "react";
import UserDetailModal from "../../modals/user_treatments/UserDetail";
const UserDetailModalBtn: React.VFC = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button className="btn btn-primary btn-xs" onClick={handleShow}>
          詳細
        </button>
      </div>
      <UserDetailModal
        showModal={showModal}
        setShowModal={setShowModal}
        user={user}
      />
    </>
  );
};
export default UserDetailModalBtn;
