import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ApplyTreatmentModal: React.VFC = ({
  showModal,
  setShowModal,
  treatment,
}) => {
  const handleClose = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>応募します。よろしいでしょうか？</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid col-md-6 col-12 ms-auto me-auto">
          <a
            href={`/users/treatments?treatment_id=${treatment.id}`}
            data-method="post"
            className="btn btn-primary btn-lg"
          >
            応募する
          </a>
        </div>
        <p className="fs-7 mt-4">
          ご住所など登録内容が変更になった場合は、プロフィール変更ページより申し込み前に、一度変更をお願いします。プロフィール変更ページは
          <a href="/users/monitors/profile">こちら</a>
        </p>
      </Modal.Body>
    </Modal>
  );
};
export default ApplyTreatmentModal;
