import React, { useState, useEffect, useRef } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

type Props = {
  setCurrentLineMessageCount: () => void;
  currentLineMessageCount: Number;
  lineMessageLimit: Number;
};

const Bar: React.VFC<Props> = (props) => {
  const label = () => {
    return `${props.currentLineMessageCount} / ${props.lineMessageLimit}`;
  };
  return (
    <>
      <ProgressBar
        now={props.currentLineMessageCount}
        label={label()}
        max={props.lineMessageLimit}
      />
    </>
  );
};
export default Bar;
