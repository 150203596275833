import React, { useState } from "react";
import { updateUser } from "../../../libs/api/user";
import toast from "react-hot-toast";
const EditFaceTypeForm: React.VFC = ({
  id,
  faceType,
  setFaceType,
  setEditType,
}) => {
  const options = () => {
    return [
      {
        name: "--",
        val: "",
      },
      {
        name: "脂肪タイプ",
        val: "type1",
      },
      {
        name: "たるみタイプ",
        val: "type2",
      },
      {
        name: "小じわタイプ",
        val: "type3",
      },
      {
        name: "ボリュームロスタイプ",
        val: "type4",
      },
    ];
  };

  const submit = async () => {
    const r = await updateUser(id, { face_type: faceType });
    setFaceType(r.data.user.face_type);
    setEditType(false);
    toast.success("顔タイプを更新しました");
  };

  return (
    <div className="input-group">
      <select
        className="custom-select"
        value={faceType}
        onChange={(e) => setFaceType(e.target.value)}
      >
        {options().map((option) => {
          return (
            <option key={option["val"]} value={option["val"]}>
              {option["name"]}
            </option>
          );
        })}
      </select>
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => submit()}
        >
          更新
        </button>
      </div>
    </div>
  );
};
export default EditFaceTypeForm;
