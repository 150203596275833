import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ReactLoading from "react-loading";
import imageCompression from "browser-image-compression";

type Props = {
  images: [File];
  setImages: (images: [File]) => void;
  compress: number;
};

const MultiImages: React.VFC<Props> = (props) => {
  const inputId = Math.random().toString(32).substring(2);

  const [loading, setLoading] = useState(false);

  const compressOptions = {
    maxSizeMB: props.compress,
  };

  const handleOnAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    var reader = new FileReader();
    reader.onload = (e) => {
      props.setImages([...props.images, e.target.result]);
    };
    setLoading(true);
    const file = await imageCompression(e.target.files[0], compressOptions);
    setLoading(false);
    reader.readAsDataURL(file);
  };

  const handleOnRemoveImage = (index: number) => {
    // 選択した画像は削除可能
    const newImages = [...props.images];
    newImages.splice(index, 1);
    props.setImages(newImages);
  };

  return (
    <>
      <label htmlFor={inputId}>
        <p>施術箇所のお写真を追加してください（複数可）</p>
        <span className="btn btn-primary">画像追加</span>
        <input
          id={inputId}
          type="file"
          multiple
          accept="image/*,.png,.jpg,.jpeg,.gif"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnAddImage(e)
          }
          style={{ display: "none" }}
        />
      </label>
      {/* 画像を選択したら選択中のすべての画像のプレビューを表示 */}

      {props.images.map((image, i) => (
        <div
          key={i}
          style={{
            position: "relative",
            width: "40%",
          }}
          className="mt-3"
        >
          <XMarkIcon
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              width: "15px",
              color: "#fff",
              background: "#000",
            }}
            onClick={() => handleOnRemoveImage(i)}
          />
          <img
            src={image}
            style={{
              width: "100%",
            }}
          />
        </div>
      ))}
      {loading && (
        <div className="bd-callout bd-callout-info text-center">
          <ReactLoading
            type="spin"
            color="#ebc634"
            height="20px"
            width="20px"
            className="mx-auto"
          />
          ファイルを読込中です...
        </div>
      )}
    </>
  );
};
export default MultiImages;
