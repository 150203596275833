import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import ReactLoading from "react-loading";

import imageCompression from "browser-image-compression";

const FormImage: React.VFC = ({ defaultImage, label, inputName, compress }) => {
  const [preview, setPreview] = useState(defaultImage);

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);

  const compressOptions = {
    maxSizeMB: compress,
  };

  return (
    <>
      {loading ? (
        <div className="bd-callout bd-callout-info text-center">
          <ReactLoading
            type="spin"
            color="#ebc634"
            height="20px"
            width="20px"
            className="mx-auto"
          />
          ファイルを読込中です...
        </div>
      ) : (
        <Form.Group className="mb-3">
          <Form.Label>{label}</Form.Label>
          <Form.Control
            name={inputName}
            type="file"
            accept="image/*"
            isInvalid={error}
            onChange={async (e) => {
              const files = e.target.files;
              if (files.length > 0) {
                //ここでファイル圧縮
                setLoading(true);
                const file = await imageCompression(files[0], compressOptions);
                setLoading(false);
                var reader = new FileReader();
                reader.onload = (e) => {
                  setPreview(e.target.result);
                  setError(false);
                };
                //ここでファイルを圧縮
                reader.readAsDataURL(file);
              } else {
                setPreview("");
              }
            }}
          />
          <input type="hidden" name={inputName} value={preview} />
          {error && (
            <Form.Control.Feedback type="invalid">
              選択してください
            </Form.Control.Feedback>
          )}
          {preview != "" && (
            <Row className="mt-2">
              <Col md="2">
                <img src={preview} />
              </Col>
            </Row>
          )}
        </Form.Group>
      )}
    </>
  );
};
export default FormImage;
