import React, { useState } from "react";
import { updateUserTreatment } from "../../../libs/api/user_treatment";
import toast from "react-hot-toast";

type Props = {
  id: string;
  status: string;
  setStatus: (v: string) => void;
  setEditStatus: (bool: boolean) => void;
  isClinic?: boolean;
};

const EditStatusForm: React.VFC<Props> = (props) => {
  const [status, setStatus] = useState(props.status);

  const options = () => {
    if (props.isClinic) {
      return [
        {
          name: "新規申し込み",
          val: "applied",
        },
        {
          name: "TEL不通",
          val: "unreachable_by_phone",
        },
        {
          name: "日程調整中",
          val: "scheduling",
        },
        {
          name: "オンライン審査日程確定",
          val: "online_date_fixed",
        },
        {
          name: "来院予約日程確定",
          val: "date_fixed",
        },
        {
          name: "不採用",
          val: "not_adopted",
        },
        {
          name: "辞退",
          val: "declined",
        },
        {
          name: "音信不通",
          val: "unresponsive",
        },
      ];
    } else {
      return [
        {
          name: "新規申し込み",
          val: "applied",
        },
        {
          name: "TEL不通",
          val: "unreachable_by_phone",
        },
        {
          name: "日程調整中",
          val: "scheduling",
        },
        {
          name: "オンライン審査日程確定",
          val: "online_date_fixed",
        },
        {
          name: "来院予約日程確定",
          val: "date_fixed",
        },
        {
          name: "不採用",
          val: "not_adopted",
        },
        {
          name: "辞退",
          val: "declined",
        },
        {
          name: "音信不通",
          val: "unresponsive",
        },
        {
          name: "完了報告済み",
          val: "report_completed",
        },
      ];
    }
  };

  const submit = async () => {
    const r = await updateUserTreatment(props.id, { status: status });
    props.setStatus(r.data.user_treatment.status);
    props.setEditStatus(false);
    toast.success("応募ステータスを更新しました");
  };

  return (
    <div className="input-group">
      <select
        className="custom-select"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        {options().map((option) => {
          return (
            <option key={option["val"]} value={option["val"]}>
              {option["name"]}
            </option>
          );
        })}
      </select>
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => submit()}
        >
          更新
        </button>
      </div>
    </div>
  );
};
export default EditStatusForm;
