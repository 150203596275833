import React, { useState, useEffect } from "react";

type Props = {
  user: any;
  setChatUser: () => void;
};

const LineMessagesUserList: React.VFC<Props> = (props) => {
  const [badge, setBadge] = useState(0);

  useEffect(() => {
    setBadge(props.user.unread_count);
  }, [props.user]);

  const selectChatUser = () => {
    props.setChatUser(props.user);
    setBadge(0);
  };
  return (
    <>
      <div
        className="line-message-user-block p-3 w-100 d-flex align-items-center cursor-pointer position-relative"
        onClick={() => selectChatUser()}
      >
        <img
          src={
            props.user.avatar_image ||
            "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
          }
          className="img-circle"
          onError={(e) =>
            (e.target.src =
              "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==")
          }
        />
        <div className="ml-3">
          <div className="text-md">{props.user.name}</div>
          <div className="text-sm text-gray pr-4">
            {props.user.last_message}
          </div>
        </div>
        <div className="message_elapsed text-gray text-xs">
          {props.user.action_status == "action_required" && (
            <span className="badge badge-primary mr-2">要対応</span>
          )}
          {props.user.action_status == "acknowledged" && (
            <span className="badge badge-success mr-2">対応済み</span>
          )}
          {props.user.message_elapsed}
        </div>
        {badge != 0 && (
          <div className="badge badge-line rounded-circle">{badge}</div>
        )}
      </div>
    </>
  );
};
export default LineMessagesUserList;
