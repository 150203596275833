import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

type Props = {
  current: Number;
  limit: Number;
};

const Bar: React.VFC<Props> = (props) => {
  const label = () => {
    return `${props.current} / ${props.limit}`;
  };
  return (
    <>
      <ProgressBar now={props.current} label={label()} max={props.limit} />
    </>
  );
};
export default Bar;
