import React, { useState } from "react";

const UsersFromStep: React.VFC = ({ step }) => {
  return (
    <div className="mt-3">
      {step == "step_basic" && (
        <ol className="step">
          <li className="is-current">情報入力</li>
          <li>写真送信</li>
          <li>完了</li>
        </ol>
      )}
      {step == "step_image" && (
        <ol className="step">
          <li className="is-current">情報入力</li>
          <li className="is-current">写真送信</li>
          <li>完了</li>
        </ol>
      )}
      {step == "step_complete" && (
        <ol className="step">
          <li className="is-current">情報入力</li>
          <li className="is-current">写真送信</li>
          <li className="is-current">完了</li>
        </ol>
      )}
    </div>
  );
};
export default UsersFromStep;
