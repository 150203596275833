import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const PlanUpgrade: React.VFC = ({ showModal, setShowModal, title, text }) => {
  const handleClose = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
        <div className="d-grid col-md-10 col-12 mx-auto">
          <a href={`/plans`} className="btn btn-primary btn-lg btn-block">
            プランをアップグレードする
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PlanUpgrade;
