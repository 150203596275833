import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
const ToastSuccess: React.VFC = ({
  showToast,
  setShowToast,
  message,
  position,
}) => {
  return (
    <>
      <ToastContainer className="p-3 position-fixed" position={position}>
        <Toast
          className="bg-success"
          show={showToast}
          onClose={() => setShowToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto text-success">
              <i className="fas fa-check me-2" />
              成功
            </strong>
          </Toast.Header>
          <Toast.Body className="text-white">{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
export default ToastSuccess;
