import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateSelfUser } from "../../libs/api/user";
import prefs from "../../constants/prefectures";
type FormState = {
  name: string;
  nickname: string;
  gender: number;
  birthday_y: string;
  birthday_m: string;
  birthday_d: string;
  tel: string;
  email: string;
  prefecture: string;
  city: string;
  term_agreed: boolean;
};

const UsersFormReview: React.VFC = ({ user, redirect_url }) => {
  const [pref, setPref] = useState("");
  const [birthYear, setBirthYear] = useState(user.birth_y);
  const [birthMonth, setBirthMonth] = useState(user.birth_m);
  const [birthDay, setBirthDay] = useState(user.birth_d);
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
    defaultValues: {
      name: user.name,
      nickname: user.nickname,
      gender: user.gender,
      birthday_y: birthYear,
      birthday_m: birthMonth,
      birthday_d: birthDay,
      prefecture: user.prefecture,
      city: user.city,
      tel: user.tel,
      email: user.email,
      term_agreed: user.term_agreed_at != null,
    },
  });

  useEffect(() => {}, [user]);

  const onSubmit = handleSubmit(async (data) => {
    setSubmitted(true);
    await updateSelfUser(data);
    //todo ここで遷移先に遷移
    location.href = redirect_url;
  });

  const yearOptions = () => {
    var currentYear = new Date().getFullYear(),
      years = [];
    let startYear = 1920;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.map((year) => {
      return (
        <option key={year} value={year}>
          {year}
        </option>
      );
    });
  };
  const monthOptions = () => {
    let months = [];
    let startMonth = 1;
    while (startMonth <= 12) {
      months.push(startMonth++);
    }
    return months.map((month) => {
      return (
        <option key={month} value={month}>
          {month}
        </option>
      );
    });
  };
  const dayOptions = () => {
    let days = [];
    const lastday = new Date(
      Number(birthYear),
      Number(birthMonth),
      0
    ).getDate();
    for (let i = 1; i <= lastday; i++) {
      days.push(i);
    }
    return days.map((day) => {
      return (
        <option key={day} value={day}>
          {day}
        </option>
      );
    });
  };
  const prefOptions = () => {
    return prefs.map((pref) => {
      return (
        <option key={pref} value={pref}>
          {pref}
        </option>
      );
    });
  };

  return (
    <>
      <div className="mt-4">
        <Form onSubmit={onSubmit}>
          <div className="card">
            <div className="card-body">
              <Form.Group className="mb-3" controlId="usersName">
                <Form.Label>
                  <span className="me-1">お名前</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  isInvalid={errors.name != null && submitted}
                  placeholder="例)佐藤 太郎"
                  {...register("name", {
                    required: "入力してください",
                  })}
                />
                {errors.name && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersNickname">
                <Form.Label>
                  <span className="me-1">ニックネーム</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder=""
                  isInvalid={errors.nickname != null && submitted}
                  {...register("nickname", {
                    required: "入力してください",
                  })}
                />
                {errors.nickname && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.nickname.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersGender">
                <Form.Label>性別</Form.Label>
                <Form.Control name="gender" as="select" {...register("gender")}>
                  <option value="female">女性</option>
                  <option value="male">男性</option>
                  <option value="other">答えたくない</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersEmail">
                <Form.Label>
                  <span className="me-1">メールアドレス</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  isInvalid={errors.email != null && submitted}
                  placeholder="例)monitor@gmail.com"
                  {...register("email", {
                    required: "入力してください",
                    pattern: {
                      value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                      message: "入力形式がメールアドレスではありません。",
                    },
                  })}
                />
                {errors.email && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersBirthday">
                <Form.Label>誕生日</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    as="select"
                    onChange={(e) => {
                      setBirthYear(e.target.value);
                    }}
                    {...register("birthday_y")}
                  >
                    {yearOptions()}
                  </FormControl>
                  <InputGroup.Text>年</InputGroup.Text>
                  <FormControl
                    as="select"
                    onChange={(e) => {
                      setBirthMonth(e.target.value);
                    }}
                    {...register("birthday_m")}
                  >
                    {monthOptions()}
                  </FormControl>
                  <InputGroup.Text>月</InputGroup.Text>
                  <FormControl
                    as="select"
                    {...register("birthday_d")}
                    onChange={(e) => {
                      setBirthDay(e.target.value);
                    }}
                  >
                    {dayOptions()}
                  </FormControl>
                  <InputGroup.Text>日</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {user.term_agreed_at == null && (
                <Form.Group className="mb-3">
                  <Form.Check
                    type={`checkbox`}
                    label={`利用規約に同意する`}
                    {...register("term_agreed", {
                      required: "規約に同意してください",
                    })}
                  />
                  {errors.term_agreed && submitted && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.term_agreed.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
            </div>

            <div className="card-footer">
              <Button
                variant="primary"
                type="submit"
                onClick={() => setSubmitted(true)}
              >
                送信する
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default UsersFormReview;
