import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getAddressFromZipCode } from "../../libs/api/zip_code";
import { updateSelfUser } from "../../libs/api/user";
import prefs from "../../constants/prefectures";
import toast from "react-hot-toast";
type FormState = {
  name: string;
  nickname: string;
  gender: number;
  birthday_y: string;
  birthday_m: string;
  birthday_d: string;
  tel: string;
  email: string;
  prefecture: string;
  city: string;
  anesthesia_allergy: boolean;
  past_cosmetic_surgery: boolean;
  cosmetic_surgery_name: string;
  has_medical_history: boolean;
  medical_history_data: string;
  sns: string;
  term_agreed: boolean;
};

type Props = {
  user: any;
  setStep: () => void;
  mode?: String;
};

const UsersFormProfile: React.VFC<Props> = (props) => {
  const [pref, setPref] = useState("");
  const [showPastCosmeticSurgery, setShowPastCosmeticSurgery] = useState(false);
  const [showMedicalHistoryData, setShowMedicalHistoryData] = useState(false);
  const [birthYear, setBirthYear] = useState(props.user.birth_y);
  const [birthMonth, setBirthMonth] = useState(props.user.birth_m);
  const [birthDay, setBirthDay] = useState(props.user.birth_d);
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
    defaultValues: {
      name: props.user.name,
      nickname: props.user.nickname,
      gender: props.user.gender,
      birthday_y: birthYear,
      birthday_m: birthMonth,
      birthday_d: birthDay,
      prefecture: props.user.prefecture,
      city: props.user.city,
      tel: props.user.tel,
      email: props.user.email,
      anesthesia_allergy: props.user.anesthesia_allergy,
      past_cosmetic_surgery: props.user.past_cosmetic_surgery,
      cosmetic_surgery_name: props.user.cosmetic_surgery_name,
      has_medical_history: props.user.has_medical_history,
      medical_history_data: props.user.medical_history_data,
      sns: props.user.sns,
      term_agreed: props.user.term_agreed_at != null,
    },
  });

  useEffect(() => {
    if (props.user.past_cosmetic_surgery) {
      setShowPastCosmeticSurgery(true);
    }
    if (props.user.has_medical_history) {
      setShowMedicalHistoryData(true);
    }
  }, [props.user]);

  const onSubmit = handleSubmit(async (data) => {
    setSubmitted(true);
    await updateSelfUser(data);
    if (props.mode == "update") {
      toast.success("更新しました");
    } else {
      props.setStep("step_image");
    }
  });

  const yearOptions = () => {
    var currentYear = new Date().getFullYear(),
      years = [];
    let startYear = 1920;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.map((year) => {
      return (
        <option key={year} value={year}>
          {year}
        </option>
      );
    });
  };
  const monthOptions = () => {
    let months = [];
    let startMonth = 1;
    while (startMonth <= 12) {
      months.push(startMonth++);
    }
    return months.map((month) => {
      return (
        <option key={month} value={month}>
          {month}
        </option>
      );
    });
  };
  const dayOptions = () => {
    let days = [];
    const lastday = new Date(
      Number(birthYear),
      Number(birthMonth),
      0
    ).getDate();
    for (let i = 1; i <= lastday; i++) {
      days.push(i);
    }
    return days.map((day) => {
      return (
        <option key={day} value={day}>
          {day}
        </option>
      );
    });
  };
  const prefOptions = () => {
    return prefs.map((pref) => {
      return (
        <option key={pref} value={pref}>
          {pref}
        </option>
      );
    });
  };

  const fillAddressFromPostalCode = async (postal_code) => {
    if (postal_code.length >= 7) {
      const data = await getAddressFromZipCode(postal_code);
      if (data.prefecture != undefined) {
        setValue("prefecture", data.prefecture);
      }
      if (data.city != undefined) {
        setValue("city", `${data.city}${data.town}`);
      }
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <div className="card">
          <div className="card-body">
            <Form.Group className="mb-3" controlId="usersName">
              <Form.Label>
                <span className="me-1">お名前</span>
                <span className="badge rounded-pill bg-primary">必須</span>
              </Form.Label>
              <Form.Control
                name="name"
                type="text"
                isInvalid={errors.name != null && submitted}
                placeholder="例)佐藤 太郎"
                {...register("name", {
                  required: "入力してください",
                })}
              />
              {errors.name && submitted && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="usersNickname">
              <Form.Label>
                <span className="me-1">ニックネーム</span>
                <span className="badge rounded-pill bg-primary">必須</span>
              </Form.Label>
              <Form.Control
                name="name"
                type="text"
                placeholder=""
                isInvalid={errors.nickname != null && submitted}
                {...register("nickname", {
                  required: "入力してください",
                })}
              />
              {errors.nickname && submitted && (
                <Form.Control.Feedback type="invalid">
                  {errors.nickname.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="usersGender">
              <Form.Label>性別</Form.Label>
              <Form.Control name="gender" as="select" {...register("gender")}>
                <option value="female">女性</option>
                <option value="male">男性</option>
                <option value="other">答えたくない</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="usersTel">
              <Form.Label>
                <span className="me-1">電話番号 -(ハイフン)あり</span>
                <span className="badge rounded-pill bg-primary">必須</span>
              </Form.Label>
              <Form.Control
                name="name"
                type="text"
                isInvalid={errors.tel != null && submitted}
                placeholder="例)080-1234-5678"
                {...register("tel", {
                  required: "入力してください",
                  pattern: {
                    value: /\d{1,5}-\d{1,4}-\d{4,5}/,
                    message: "入力形式が間違っています",
                  },
                })}
              />
              {errors.tel && submitted && (
                <Form.Control.Feedback type="invalid">
                  {errors.tel.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="usersEmail">
              <Form.Label>
                <span className="me-1">メールアドレス</span>
                <span className="badge rounded-pill bg-primary">必須</span>
              </Form.Label>
              <Form.Control
                name="name"
                type="text"
                isInvalid={errors.email != null && submitted}
                placeholder="例)monitor@gmail.com"
                {...register("email", {
                  required: "入力してください",
                  pattern: {
                    value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                    message: "入力形式がメールアドレスではありません。",
                  },
                })}
              />
              {errors.email && submitted && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="usersBirthday">
              <Form.Label>誕生日</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  as="select"
                  onChange={(e) => {
                    setBirthYear(e.target.value);
                  }}
                  {...register("birthday_y")}
                >
                  {yearOptions()}
                </FormControl>
                <InputGroup.Text>年</InputGroup.Text>
                <FormControl
                  as="select"
                  onChange={(e) => {
                    setBirthMonth(e.target.value);
                  }}
                  {...register("birthday_m")}
                >
                  {monthOptions()}
                </FormControl>
                <InputGroup.Text>月</InputGroup.Text>
                <FormControl
                  as="select"
                  {...register("birthday_d")}
                  onChange={(e) => {
                    setBirthDay(e.target.value);
                  }}
                >
                  {dayOptions()}
                </FormControl>
                <InputGroup.Text>日</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Row>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="userPref">
                  <Form.Label>
                    <span className="me-1">都道府県</span>
                    <span className="badge rounded-pill bg-primary">必須</span>
                  </Form.Label>
                  <FormControl
                    name="prefecture"
                    isInvalid={errors.prefecture != null && submitted}
                    as="select"
                    {...register("prefecture", {
                      required: "入力してください",
                    })}
                    onChange={(e) => setPref(e.target.value)}
                  >
                    {prefOptions()}
                  </FormControl>
                  {errors.prefecture && submitted && (
                    <Form.Control.Feedback type="invalid">
                      {errors.prefecture.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3} md={6}>
                <Form.Group className="mb-3" controlId="userCity">
                  <Form.Label>
                    <span className="me-1">市区町村</span>
                    <span className="badge rounded-pill bg-primary">必須</span>
                  </Form.Label>
                  <Form.Control
                    name="city"
                    type="text"
                    isInvalid={errors.city != null && submitted}
                    placeholder="例)港区高輪"
                    {...register("city", {
                      required: "入力してください",
                    })}
                  />
                  {errors.city && submitted && (
                    <Form.Control.Feedback type="invalid">
                      {errors.city.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="usersSNS">
              <Form.Label>
                <span className="me-1">SNS</span>
              </Form.Label>
              <Form.Control
                name="name"
                type="text"
                isInvalid={errors.sns != null && submitted}
                placeholder="例)https://..."
                {...register("sns")}
              />
            </Form.Group>
            <div className="mb-3">
              <Form.Check
                type={`checkbox`}
                label={`麻酔のアレルギー有り`}
                {...register("anesthesia_allergy")}
              />
            </div>
            <div className="mb-3">
              <Form.Check
                type={`checkbox`}
                label={`過去の美容手術の経験有り`}
                {...register("past_cosmetic_surgery")}
                onChange={(e) => {
                  setShowPastCosmeticSurgery(e.target.checked);
                }}
              />
            </div>
            {showPastCosmeticSurgery == true && (
              <Form.Group className="mb-3 ms-4" controlId="userBuilding">
                <Form.Label>
                  過去の美容手術の詳細(施術名、箇所)をお願いします。
                </Form.Label>
                <Form.Control
                  name="cosmetic_surgery_name"
                  as="textarea"
                  rows={3}
                  isInvalid={errors.cosmetic_surgery_name != null && submitted}
                  {...register("cosmetic_surgery_name", {
                    required: "入力してください",
                  })}
                  placeholder={`(例)ボトックス眉間`}
                />
                <p className="text-xs text-muted">
                  ※モニター案件によっては受けられない場合がございます。
                </p>
                {errors.cosmetic_surgery_name && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.cosmetic_surgery_name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}

            <div className="mb-3">
              <Form.Check
                type={`checkbox`}
                label={`既往歴(過去に罹患した病気や健康状態)`}
                {...register("has_medical_history")}
                onChange={(e) => {
                  setShowMedicalHistoryData(e.target.checked);
                }}
              />
            </div>
            {showMedicalHistoryData == true && (
              <Form.Group className="mb-3 ms-4" controlId="userBuilding">
                <Form.Label>
                  既往歴の詳細(高血圧、腎臓病、肝臓病など)をお願いします。
                </Form.Label>
                <Form.Control
                  name="medical_history_data"
                  as="textarea"
                  rows={3}
                  isInvalid={errors.medical_history_data != null && submitted}
                  {...register("medical_history_data", {
                    required: "入力してください",
                  })}
                />
                {errors.medical_history_data && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.medical_history_data.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
            {props.user.term_agreed_at == null && (
              <Form.Group className="mb-3">
                <Form.Check
                  type={`checkbox`}
                  label={`利用規約に同意する`}
                  {...register("term_agreed", {
                    required: "規約に同意してください",
                  })}
                />
                {errors.term_agreed && submitted && (
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.term_agreed.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
          </div>

          <div className="card-footer">
            <Button
              variant="primary"
              type="submit"
              onClick={() => setSubmitted(true)}
            >
              {props.mode == "update" ? <>更新する</> : <>次へ</>}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default UsersFormProfile;
