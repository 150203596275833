import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateSelfUserFromEvent } from "../../libs/api/user";
import prefs from "../../constants/prefectures";
type FormState = {
  name: string;
  nickname: string;
  gender: number;
  birthday_y: string;
  birthday_m: string;
  birthday_d: string;
  tel: string;
  email: string;
  prefecture: string;
  city: string;
  term_agreed: boolean;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  postal_code: string;
  address_town: string;
  address_building: string;
  occupation: string;
};

const UsersFormEvent: React.VFC = ({ user, redirect_url }) => {
  const [pref, setPref] = useState("");
  const [birthYear, setBirthYear] = useState(user.birth_y);
  const [birthMonth, setBirthMonth] = useState(user.birth_m);
  const [birthDay, setBirthDay] = useState(user.birth_d);
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
    defaultValues: {
      name: user.name,
      nickname: user.nickname,
      gender: user.gender,
      birthday_y: birthYear,
      birthday_m: birthMonth,
      birthday_d: birthDay,
      prefecture: user.prefecture,
      city: user.city,
      tel: user.tel,
      email: user.email,
      term_agreed: user.term_agreed_at != null,
      last_name: user.last_name, // ここに新しいデフォルト値を追加
      first_name: user.first_name,
      last_name_kana: user.last_name_kana,
      first_name_kana: user.first_name_kana,
      postal_code: user.postal_code,
      address_town: user.address_town,
      address_building: user.address_building,
      occupation: user.occupation,
    },
  });

  useEffect(() => {}, [user]);

  const onSubmit = handleSubmit(async (data) => {
    setSubmitted(true);
    //console.log(data);
    let res = await updateSelfUserFromEvent(data);
    if (res.data.error) {
      alert(res.data.message);
    } else {
      //todo ここで遷移先に遷移
      location.href = redirect_url;
    }
  });

  const yearOptions = () => {
    var currentYear = new Date().getFullYear(),
      years = [];
    let startYear = 1920;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.map((year) => {
      return (
        <option key={year} value={year}>
          {year}
        </option>
      );
    });
  };
  const monthOptions = () => {
    let months = [];
    let startMonth = 1;
    while (startMonth <= 12) {
      months.push(startMonth++);
    }
    return months.map((month) => {
      return (
        <option key={month} value={month}>
          {month}
        </option>
      );
    });
  };
  const dayOptions = () => {
    let days = [];
    const lastday = new Date(
      Number(birthYear),
      Number(birthMonth),
      0
    ).getDate();
    for (let i = 1; i <= lastday; i++) {
      days.push(i);
    }
    return days.map((day) => {
      return (
        <option key={day} value={day}>
          {day}
        </option>
      );
    });
  };
  const prefOptions = () => {
    return prefs.map((pref) => {
      return (
        <option key={pref} value={pref}>
          {pref}
        </option>
      );
    });
  };

  return (
    <>
      <div className="mt-4">
        <Form onSubmit={onSubmit}>
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="usersLastName">
                    <Form.Label>
                      <span className="me-1">姓</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="last_name"
                      type="text"
                      isInvalid={errors.last_name != null && submitted}
                      {...register("last_name", {
                        required: "入力してください",
                      })}
                    />
                    {errors.last_name && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.last_name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="usersFirstName">
                    <Form.Label>
                      <span className="me-1">名</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="first_name"
                      type="text"
                      isInvalid={errors.first_name != null && submitted}
                      {...register("first_name", {
                        required: "入力してください",
                      })}
                    />
                    {errors.first_name && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.first_name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="usersLastNameKana">
                    <Form.Label>
                      <span className="me-1">セイ</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="lastNameKana"
                      type="text"
                      isInvalid={errors.last_name_kana != null && submitted}
                      {...register("last_name_kana", {
                        required: "入力してください",
                      })}
                    />
                    {errors.last_name_kana && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.last_name_kana.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} md={6}>
                  <Form.Group className="mb-3" controlId="usersFirstNameKana">
                    <Form.Label>
                      <span className="me-1">メイ</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="firstNameKana"
                      type="text"
                      isInvalid={errors.first_name_kana != null && submitted}
                      {...register("first_name_kana", {
                        required: "入力してください",
                      })}
                    />
                    {errors.first_name_kana && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.first_name_kana.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="usersNickname">
                <Form.Label>
                  <span className="me-1">ニックネーム</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder=""
                  isInvalid={errors.nickname != null && submitted}
                  {...register("nickname", {
                    required: "入力してください",
                  })}
                />
                {errors.nickname && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.nickname.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersGender">
                <Form.Label>
                  <span className="me-1">性別</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control name="gender" as="select" {...register("gender")}>
                  <option value="female">女性</option>
                  <option value="male">男性</option>
                  <option value="other">答えたくない</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersTel">
                <Form.Label>
                  <span className="me-1">電話番号 -(ハイフン)あり</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  isInvalid={errors.tel != null && submitted}
                  placeholder="例)080-1234-5678"
                  {...register("tel", {
                    required: "入力してください",
                    pattern: {
                      value: /\d{1,5}-\d{1,4}-\d{4,5}/,
                      message: "入力形式が間違っています",
                    },
                  })}
                />
                {errors.tel && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.tel.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersEmail">
                <Form.Label>
                  <span className="me-1">メールアドレス</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  isInvalid={errors.email != null && submitted}
                  placeholder="例)monitor@gmail.com"
                  {...register("email", {
                    required: "入力してください",
                    pattern: {
                      value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                      message: "入力形式がメールアドレスではありません。",
                    },
                  })}
                />
                {errors.email && submitted && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="usersBirthday">
                <Form.Label>
                  <span className="me-1">誕生日</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    as="select"
                    onChange={(e) => {
                      setBirthYear(e.target.value);
                    }}
                    {...register("birthday_y")}
                  >
                    {yearOptions()}
                  </FormControl>
                  <InputGroup.Text>年</InputGroup.Text>
                  <FormControl
                    as="select"
                    onChange={(e) => {
                      setBirthMonth(e.target.value);
                    }}
                    {...register("birthday_m")}
                  >
                    {monthOptions()}
                  </FormControl>
                  <InputGroup.Text>月</InputGroup.Text>
                  <FormControl
                    as="select"
                    {...register("birthday_d")}
                    onChange={(e) => {
                      setBirthDay(e.target.value);
                    }}
                  >
                    {dayOptions()}
                  </FormControl>
                  <InputGroup.Text>日</InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Row>
                <Col lg={3} md={6}>
                  <Form.Group className="mb-3" controlId="usersPostalCode">
                    <Form.Label>
                      <span className="me-1">郵便番号</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="postal_code"
                      type="text"
                      isInvalid={errors.postal_code != null && submitted}
                      {...register("postal_code", {
                        required: "入力してください",
                      })}
                    />
                    {errors.postal_code && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.postal_code.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={3} md={6}>
                  <Form.Group className="mb-3" controlId="userPref">
                    <Form.Label>
                      <span className="me-1">都道府県</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <FormControl
                      name="prefecture"
                      isInvalid={errors.prefecture != null && submitted}
                      as="select"
                      {...register("prefecture", {
                        required: "入力してください",
                      })}
                      onChange={(e) => setPref(e.target.value)}
                    >
                      {prefOptions()}
                    </FormControl>
                    {errors.prefecture && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.prefecture.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={3} md={6}>
                  <Form.Group className="mb-3" controlId="userCity">
                    <Form.Label>
                      <span className="me-1">市区町村</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="city"
                      type="text"
                      isInvalid={errors.city != null && submitted}
                      placeholder="例)港区高輪"
                      {...register("city", {
                        required: "入力してください",
                      })}
                    />
                    {errors.city && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.city.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={3} md={6}>
                  <Form.Group className="mb-3" controlId="usersTownStreet">
                    <Form.Label>
                      <span className="me-1">町番地</span>
                      <span className="badge rounded-pill bg-primary">
                        必須
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="address_town"
                      type="text"
                      isInvalid={errors.address_town != null && submitted}
                      {...register("address_town", {
                        required: "入力してください",
                      })}
                    />
                    {errors.address_town && submitted && (
                      <Form.Control.Feedback type="invalid">
                        {errors.address_town.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="usersBuildingApartment"
                  >
                    <Form.Label>建物マンション名</Form.Label>
                    <Form.Control
                      name="address_building"
                      type="text"
                      {...register("address_building")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="usersOccupation">
                <Form.Label>
                  <span className="me-1">職種</span>
                  <span className="badge rounded-pill bg-primary">必須</span>
                </Form.Label>
                <Form.Control
                  name="occupation"
                  as="select"
                  {...register("occupation")}
                >
                  <option value="01">ご登壇者様</option>
                  <option value="02">医師</option>
                  <option value="03">歯科医師</option>
                  <option value="04">医科_医療関係者</option>
                  <option value="05">歯科_医療関係者</option>
                  <option value="06">看護師</option>
                  <option value="07">企業</option>
                  <option value="08">出展社</option>
                  <option value="09">その他（医療関係者以外）</option>
                </Form.Control>
              </Form.Group>
              {user.term_agreed_at == null && (
                <Form.Group className="mb-3">
                  <Form.Check
                    type={`checkbox`}
                    label={`プライバシーポリシーと利用規約に同意して申込む`}
                    {...register("term_agreed", {
                      required: "規約に同意してください",
                    })}
                  />
                  {errors.term_agreed && submitted && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.term_agreed.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              <div className="">
                <a href="/pages/contract" target="_blank" className="me-2">
                  利用規約
                </a>
                <a href="/pages/privacy" target="_blank">
                  プライバシーポリシー
                </a>
              </div>
            </div>

            <div className="card-footer">
              <Button
                variant="primary"
                type="submit"
                onClick={() => setSubmitted(true)}
              >
                送信する
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default UsersFormEvent;
