import React, { useState } from "react";
import NewMonitorImageModal from "../modals/NewMonitorImage";
import SignInModal from "../modals/SignIn";
import ApplyTreatmentModal from "../modals/ApplyTreatment";
type Props = {
  user: any;
  treatment: any;
  applied: boolean;
  signInUrl: string;
  newMonitorUrl: string;
};
const TreatmentApply: React.VFC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const applyModal = () => {
    if (props.user == null) {
      return (
        <SignInModal
          showModal={showModal}
          setShowModal={setShowModal}
          url={props.signInUrl}
          title={`施術の応募には会員登録が必要です`}
        />
      );
    } else {
      if (props.user.form_step == "step_complete") {
        return (
          <ApplyTreatmentModal
            showModal={showModal}
            setShowModal={setShowModal}
            treatment={props.treatment}
          />
        );
      } else {
        return (
          <NewMonitorImageModal
            showModal={showModal}
            setShowModal={setShowModal}
            url={props.newMonitorUrl}
          />
        );
      }
    }
  };
  if (props.treatment.status == "closed") {
    return (
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button disabled className="btn btn-primary btn-lg">
          募集は終了しました
        </button>
      </div>
    );
  }
  if (props.applied == true) {
    return (
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button className="btn btn-outline-primary btn-lg">応募済み</button>
      </div>
    );
  }
  if (props.treatment?.current_apply_count >= props.treatment?.apply_limit) {
    return (
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button className="btn btn-primary btn-lg" disabled>
          定員に達しました
        </button>
      </div>
    );
  }
  return (
    <>
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button className="btn btn-primary btn-lg" onClick={handleShow}>
          <i className="fas fa-angle-right me-2"></i>応募する
        </button>
      </div>
      {applyModal()}
    </>
  );
};
export default TreatmentApply;
