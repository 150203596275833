import React, { useState, useEffect } from "react";
import Chat from "./Chat";
import consumer from "../../../channels/consumer";

import { ActionCableProvider } from "react-actioncable-provider";

type Props = {
  clinic: [any];
  current_user: [any];
  uid: String;
  ws_host: String;
  clinic_icon: String;
};

const LineMessages: React.VFC<Props> = (props) => {
  const [clinic, setClinic] = useState(props.clinic);
  const [channel, setChannel] = useState("");

  useEffect(() => {
    //チャンネル作成
    const LineMessageChannel = consumer.subscriptions.create(
      {
        channel: "LineMessageChannel",
        uid: props.uid,
      },
      {
        send_user_message(message, currentUserId, targetClinicId) {
          this.perform("send_user_message", {
            message: message,
            current_user_id: currentUserId,
            target_clinic_id: targetClinicId,
          });
        },
      }
    );
    setChannel(LineMessageChannel);
  }, [props.users]);

  return (
    <>
      <ActionCableProvider cable={consumer}>
        <div className="line-message-wrap d-flex">
          <div className="chat w-100">
            <Chat
              currentUser={props.current_user}
              clinic={clinic}
              setClinic={setClinic}
              channel={channel}
              uid={props.uid}
              clinicId={props.clinic_id}
              clinicIcon={props.clinic_icon}
            ></Chat>
          </div>
        </div>
      </ActionCableProvider>
    </>
  );
};
export default LineMessages;
