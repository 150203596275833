import React, { useState } from "react";
import SignInModal from "../modals/SignIn";
import ApplyEvent from "../modals/ApplyEvent";
type Props = {
  user: any;
  event: any;
  applied: boolean;
  signInUrl: string;
};
const TreatmentApply: React.VFC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const applyModal = () => {
    if (props.user == null) {
      return (
        <SignInModal
          showModal={showModal}
          setShowModal={setShowModal}
          url={props.signInUrl}
          title={`イベントの参加には会員登録が必要です`}
        />
      );
    } else {
      return (
        <ApplyEvent
          showModal={showModal}
          setShowModal={setShowModal}
          event={props.event}
        />
      );
    }
  };
  if (props.applied == true) {
    return (
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button className="btn btn-outline-primary btn-lg">申し込み済み</button>
      </div>
    );
  }
  return (
    <>
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button className="btn btn-primary btn-lg" onClick={handleShow}>
          <i className="fas fa-angle-right me-2"></i>参加する
        </button>
      </div>
      {applyModal()}
    </>
  );
};
export default TreatmentApply;
