import getClient from "./client";

/**
 * カテゴリ一覧
 */
export const uploadImage = async (params) => {
  return getClient().post(`images/create`, params);
};

/**
 * カテゴリ一覧
 */
export const updateImage = async (params) => {
  return getClient().put(`images/update`, params);
};
