import React, { useState } from "react";
import { updateUserTreatment } from "../../../libs/api/user_treatment";
import toast from "react-hot-toast";
const EditRankForm: React.VFC = ({ id, rank, setRank, setEditRank }) => {
  const options = () => {
    return [
      {
        name: "--",
        val: "",
      },
      {
        name: "Aランク",
        val: "a_rank",
      },
      {
        name: "Bランク",
        val: "b_rank",
      },
      {
        name: "Cランク",
        val: "c_rank",
      },
      {
        name: "Dランク",
        val: "d_rank",
      },
    ];
  };

  const submit = async () => {
    const r = await updateUserTreatment(id, { rank: rank });
    setRank(r.data.user_treatment.rank);
    setEditRank(false);
    toast.success("モニター有力度を更新しました");
  };

  return (
    <div className="input-group">
      <select
        className="custom-select"
        value={rank}
        onChange={(e) => setRank(e.target.value)}
      >
        {options().map((option) => {
          return (
            <option key={option["val"]} value={option["val"]}>
              {option["name"]}
            </option>
          );
        })}
      </select>
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => submit()}
        >
          更新
        </button>
      </div>
    </div>
  );
};
export default EditRankForm;
