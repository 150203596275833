import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ApplyEventModal: React.VFC = ({ showModal, setShowModal, event }) => {
  const handleClose = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>参加します。よろしいでしょうか？</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid col-md-6 col-12 ms-auto me-auto">
          <a
            href={`/users/events?event_id=${event.id}`}
            data-method="post"
            className="btn btn-primary btn-lg"
          >
            参加する
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ApplyEventModal;
