window.toggleShowFormCheck = (e, className) => {
  const elements = document.getElementsByClassName(className);
  if(e.target.checked){
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'block';
    }
  } else {
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
  }
}
window.toggleShowFormRadio = (e, v, className) => {
  console.log(e.target.value,v,className)
  const elements = document.getElementsByClassName(className);
  if(e.target.value == v){
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'block';
    }
  } else {
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
  }
}
