import React, { useState } from "react";
import Masonry from "react-masonry-css";
import UserList from "./UserList";
import Bar from "./Bar";
import { Toaster } from "react-hot-toast";

type Props = {
  user_treatments: [any];
  favorite_ids: [Number];
  entry_limit?: Number;
  current_entry_count?: Number;
};

const UserTreatmentsAdmin: React.VFC<Props> = (props) => {
  const [favoriteIds, setFavoriteIds] = useState(props.favorite_ids);
  const breakpointColumnsObj = {
    default: 5,
    1350: 4,
    1048: 2,
    576: 1,
  };

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {props.user_treatments.map((user_treatment) => {
          return (
            <UserList
              key={user_treatment.id}
              user_treatment={user_treatment}
              favoriteIds={favoriteIds}
              setFavoriteIds={setFavoriteIds}
            ></UserList>
          );
        })}
      </Masonry>
      <Toaster />
    </>
  );
};
export default UserTreatmentsAdmin;
