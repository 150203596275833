import React, { useState } from "react";
import { Modal } from "react-bootstrap";

type Props = {
  showModal: boolean;
  setShowModal: (bool) => void;
  url: string;
  title: string;
};

const NewMonitoImage: React.VFC<Props> = (props) => {
  const handleClose = () => props.setShowModal(false);
  return (
    <Modal show={props.showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid col-md-6 col-12 ms-auto me-auto">
          <a href={props.url} data-method="post" className="btn bg-line btn-lg">
            <i className="fab fa-line me-2"></i>
            LINEで登録して応募する
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default NewMonitoImage;
