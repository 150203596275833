import React, { useEffect, useState } from "react";
import FormStep from "./FormStep";
import FormProfile from "./FormProfile";
import FormImage from "./FormImage";
import FormThanks from "./FormThanks";
const UsersFormMonitor: React.VFC = ({ user, image }) => {
  const [step, setStep] = useState("step_basic");

  useEffect(() => {
    setStep(user.form_step);
  }, [user]);

  return (
    <>
      <FormStep step={step} />
      {step == "step_basic" && (
        <div className="p-2">
          <p className="mb-1">
            モニター案件にご応募するためには、下記のモニター登録が必要になります。
          </p>
          <p>
            モニター案件は、申し込み人数に達し次第締め切りになりますので予めご了承ください
          </p>
          <FormProfile setStep={setStep} user={user} />
        </div>
      )}
      {step == "step_image" && (
        <div className="p-2">
          <p className="mb-1">
            お顔の状態が分かるよう、ノーメイクの状態で、正面・斜め左右のお写真合計3枚を、
            加工アプリを使わずに撮影しアップロードしてください。
          </p>
          <p>
            肌状態が良くわかるお写真だと選定しやすいため、当確しやすくなります。
          </p>
          <FormImage setStep={setStep} image={image} mode={`create`} />
        </div>
      )}
      {step == "step_complete" && <FormThanks />}
    </>
  );
};
export default UsersFormMonitor;
