import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateUserTreatment } from "../../libs/api/user_treatment";

import ReactStars from "react-rating-stars-component";
import toast, { Toaster } from "react-hot-toast";

type FormState = {
  id: string;
  review_point: number;
  review_comment: string;
};

type Props = {
  user_treatment: any;
};

const EvaluateForm: React.VFC<Props> = (props) => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
    defaultValues: {
      review_point: props.user_treatment.review_point,
      review_comment: props.user_treatment.review_comment,
    },
  });

  useEffect(() => {}, [props.user_treatment]);

  const onSubmit = handleSubmit(async (data) => {
    await updateUserTreatment(props.user_treatment.id, data);
    toast.success("評価しました");
  });

  const ratingChanged = (newRating) => {
    setValue("review_point", newRating);
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <div className="card mt-2">
          <div className="card-body">
            <div className="mb-3">
              <label className="text optional" htmlFor="review_point">
                <span className="me-1">総合評価(タップしてください)</span>
              </label>
              <div className="d-flex align-item-center mt-2 fs-2">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={32}
                  activeColor="#ffd700"
                  value={getValues("review_point")}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group text optional review_comment">
                <label className="text optional" htmlFor="review_comment">
                  感想・コメント
                </label>
                <Form.Control
                  name="review_comment"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("review_comment")}
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <Button variant="primary" type="submit">
              送信する
            </Button>
          </div>
        </div>
        <Toaster />
      </Form>
    </>
  );
};
export default EvaluateForm;
