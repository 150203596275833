import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import FullImage from "../FullImage";
import EditStatusForm from "./EditStatusForm";
import EditRankForm from "./EditRankForm";
import EditFaceTypeForm from "./EditFaceTypeForm";
import EditExamDateForm from "./EditExamDateForm";
import toast from "react-hot-toast";

import { updateUserTreatmentDateStatus } from "../../../libs/api/user_treatment";

type Props = {
  id: String;
  showModal: boolean;
  setShowModal: () => void;
  user: any;
  status: String;
  rank: String;
  faceType: String;
  examDate: Date;
  webExamDate: Date;
  visitedAt: Date;
  treatCompletedAt: Date;
  liquidatedAt: Date;
  reviewPoint: number;
  reviewComment: string;
  setStatus: () => void;
  setRank: () => void;
  setFaceType: () => void;
  statusName: () => void;
  rankName: () => void;
  faceTypeName: () => void;
  isClinic?: boolean;
};

const AdminUserDetail: React.VFC<Props> = (props) => {
  const handleClose = () => props.setShowModal(false);
  const [fullImage, setFullImage] = useState("");
  const [showFullImageModal, setShowFullImageModal] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editRank, setEditRank] = useState(false);
  const [editType, setEditType] = useState(false);
  const [examDate, setExamDate] = useState(props.examDate);
  const [webExamDate, setWebExamDate] = useState(props.webExamDate);
  const [editExamDate, setEditExamDate] = useState(false);
  const [treatCompleted, setTreatCompleted] = useState(
    props.treatCompletedAt != null
  );
  const [visited, setVisited] = useState(props.visitedAt != null);
  const [liquidated, setLiquidated] = useState(props.liquidatedAt != null);
  const [statusDateDirty, setStatusDateDirty] = useState(false);

  const editStatusOptions = [
    "applied",
    "unreachable_by_phone",
    "scheduling",
    "online_date_fixed",
    "date_fixed",
    "operation_completed",
    "not_adopted",
    "declined",
    "unresponsive",
    "report_completed",
  ];

  const handleStatusDateSubmit = async () => {
    if (visited) {
      if (window.confirm("変更すると元に戻せないですが、変更しますか？")) {
        await updateUserTreatmentDateStatus(
          props.id,
          treatCompleted,
          visited,
          liquidated
        );
        toast.success("応募ステータスを更新しました");
      }
    } else if (treatCompleted) {
      if (window.confirm("変更すると元に戻せないですが、変更しますか？")) {
        await updateUserTreatmentDateStatus(
          props.id,
          treatCompleted,
          visited,
          liquidated
        );
        toast.success("応募ステータスを更新しました");
      }
    } else {
      await updateUserTreatmentDateStatus(
        props.id,
        treatCompleted,
        visited,
        liquidated
      );
      toast.success("応募ステータスを更新しました");
    }

    setStatusDateDirty(false);
  };

  const lineMessageUrl = () => {
    if (props.isClinic) {
      return `/clinics/users/${props.user.id}/new_line_message`;
    } else {
      return `/admins/users/${props.user.id}/new_line_message`;
    }
  };

  return (
    <>
      <Modal size="lg" show={props.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.user.name}様の詳細情報</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex p-2 justify-content-between border-bottom">
            <div className="text-bold w-50">麻酔のアレルギー</div>
            <div className="w-50">
              {props.user.anesthesia_allergy ? <>あり</> : <>--</>}
            </div>
          </div>
          <div className="d-flex p-2 justify-content-between border-bottom">
            <div className="text-bold w-50">過去の美容手術の経験</div>
            <div className="w-50">
              {props.user.past_cosmetic_surgery ? <>あり</> : <>--</>}
            </div>
          </div>
          <div className="d-flex p-2 justify-content-between border-bottom">
            <div className="text-bold w-50">過去の美容整形名</div>
            <div className="w-50">
              {props.user.cosmetic_surgery_name ? (
                <>{props.user.cosmetic_surgery_name}</>
              ) : (
                <>--</>
              )}
            </div>
          </div>
          <div className="d-flex p-2 justify-content-between border-bottom">
            <div className="text-bold w-50">既往歴の詳細</div>
            <div className="w-50">
              {props.user.medical_history_data ? (
                <>{props.user.medical_history_data}</>
              ) : (
                <>--</>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-4">
              <label>顔正面</label>
              <img
                className="cursor-zoom-in"
                src={props.user.face_front_image}
                onClick={() => {
                  setFullImage(props.user.face_front_image);
                  setShowFullImageModal(true);
                }}
              />
            </div>
            <div className="col-4">
              <label>顔左</label>
              <img
                className="cursor-zoom-in"
                src={props.user.face_left_image}
                onClick={() => {
                  setFullImage(props.user.face_left_image);
                  setShowFullImageModal(true);
                }}
              />
            </div>
            <div className="col-4">
              <label>顔右</label>
              <img
                className="cursor-zoom-in"
                src={props.user.face_right_image}
                onClick={() => {
                  setFullImage(props.user.face_right_image);
                  setShowFullImageModal(true);
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="row border-top pt-2 pb-2">
              <div className="text-bold col-4 col-md-6">応募ステータス</div>

              <div className="col-8 col-md-6">
                {editStatus ? (
                  <>
                    <EditStatusForm
                      id={props.id}
                      status={props.status}
                      setStatus={props.setStatus}
                      setEditStatus={setEditStatus}
                      isClinic={props.isClinic}
                    ></EditStatusForm>
                    <div className="text-right mt-2">
                      <span
                        className="color-link cursor-pointer"
                        onClick={() => setEditStatus(false)}
                      >
                        <i className="fas fa-times mr-1"></i>キャンセル
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="mr-2">{props.statusName()}</span>
                    {editStatusOptions.includes(props.status) && (
                      <span
                        className="color-link cursor-pointer"
                        onClick={() => setEditStatus(true)}
                      >
                        <i className="fas fa-edit mr-1"></i>編集
                      </span>
                    )}
                  </>
                )}
                <div className="mt-2">
                  <EditExamDateForm
                    id={props.id}
                    examDate={examDate}
                    setExamDate={setExamDate}
                    webExamDate={webExamDate}
                    setWebExamDate={setWebExamDate}
                    editExamDate={editExamDate}
                    setEditExamDate={setEditExamDate}
                    status={props.status}
                    setStatus={props.setStatus}
                  />
                </div>
                <div className="flex flex-between">
                  <div className="">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={visited}
                        onChange={(e) => {
                          setVisited(e.target.checked);
                          setStatusDateDirty(true);
                        }}
                        disabled={props.visitedAt != null}
                        value="1"
                      />
                      <label htmlFor="flexCheckDefault" className="mb-0">
                        来院済み
                      </label>
                      <p className="text-xs text-muted mb-1">
                        ※来院済みに変更すると、1送客として請求がカウントされます
                      </p>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={treatCompleted}
                        onChange={(e) => {
                          setTreatCompleted(e.target.checked);
                          setStatusDateDirty(true);
                        }}
                        disabled={props.treatCompletedAt != null}
                        value="1"
                      />
                      <label htmlFor="flexCheckDefault" className="mb-0">
                        施術完了
                      </label>
                      <p className="text-xs text-muted mb-1">
                        ※施術完了に変更すると、モニターに完了報告フォームが送信されます
                      </p>
                    </div>
                    {props.isClinic != true && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={liquidated}
                          onChange={(e) => {
                            setLiquidated(e.target.checked);
                            setStatusDateDirty(true);
                          }}
                          value="1"
                        />
                        <label htmlFor="flexCheckDefault">精算済み</label>
                      </div>
                    )}
                  </div>
                  {statusDateDirty && (
                    <>
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={() => handleStatusDateSubmit()}
                      >
                        更新
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row border-top pt-2 pb-2">
              <div className="text-bold col-4 col-md-6">モニター有力度</div>
              <div className="col-8 col-md-6">
                {editRank ? (
                  <>
                    <EditRankForm
                      rank={props.rank}
                      id={props.id}
                      setRank={props.setRank}
                      setEditRank={setEditRank}
                    ></EditRankForm>
                    <div className="text-right mt-2">
                      <span
                        className="color-link cursor-pointer"
                        onClick={() => setEditRank(false)}
                      >
                        <i className="fas fa-times mr-1"></i>キャンセル
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="mr-2">{props.rankName()}</span>
                    <span
                      className="color-link cursor-pointer"
                      onClick={() => setEditRank(true)}
                    >
                      <i className="fas fa-edit mr-1"></i>編集
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="row border-top pt-2 pb-2">
              <div className="text-bold col-4 col-md-6">顔タイプ</div>
              <div className="col-8 col-md-6">
                {editType ? (
                  <>
                    <EditFaceTypeForm
                      faceType={props.faceType}
                      id={props.user.id}
                      setFaceType={props.setFaceType}
                      setEditType={setEditType}
                    ></EditFaceTypeForm>
                    <div className="text-right mt-2">
                      <span
                        className="color-link cursor-pointer"
                        onClick={() => setEditType(false)}
                      >
                        <i className="fas fa-times mr-1"></i>キャンセル
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="mr-2">{props.faceTypeName()}</span>
                    <span
                      className="color-link cursor-pointer"
                      onClick={() => setEditType(true)}
                    >
                      <i className="fas fa-edit mr-1"></i>編集
                    </span>
                  </>
                )}
              </div>
            </div>

            <div className="row border-top pt-2 pb-2">
              <div className="text-bold col-4 col-md-6">メッセージ</div>
              <div className="col-8 col-md-6">
                <a className="btn btn-primary btn-xs" href={lineMessageUrl()}>
                  LINE送信
                </a>
              </div>
            </div>
          </div>
          {props.isClinic != true && (
            <>
              {props.reviewPoint != null && (
                <div className="row border-top pt-2 pb-2">
                  <div className="text-bold col-4 col-md-6">
                    モニター評価点数
                  </div>
                  <div className="col-8 col-md-6">{props.reviewPoint}</div>
                </div>
              )}
              {props.reviewComment != null && (
                <div className="row border-top pt-2 pb-2">
                  <div className="text-bold col-4 col-md-6">
                    モニター評価コメント
                  </div>
                  <div className="col-8 col-md-6">{props.reviewComment}</div>
                </div>
              )}
            </>
          )}
          {props.user.entry_logs.length > 0 && props.isClinic != true && (
            <div className="mt-4">
              <h3 className="text-md text-bold">モニターエントリー履歴</h3>
              <div className="d-flex p-2 justify-content-between border-bottom">
                <div className="text-bold w-50">日付</div>
                <div className="text-bold w-50">内容</div>
              </div>
              {props.user.entry_logs.map((entry_log) => {
                return (
                  <div key={entry_log.id}>
                    <div className="d-flex p-2 justify-content-between border-bottom">
                      <div className="w-50">{entry_log.created_at}</div>
                      <div className="w-50">
                        <span className="mr-2">{entry_log.clinic_name}</span>
                        <span className="mr-2">/</span>
                        <span>{entry_log.treatment_title}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Modal.Body>
      </Modal>
      <FullImage
        showModal={showFullImageModal}
        setShowModal={setShowFullImageModal}
        imageUrl={fullImage}
      ></FullImage>
    </>
  );
};
export default AdminUserDetail;
