import React, { useState } from "react";

import PlanUpgradeModal from "../modals/PlanUpgrade";

type Props = {
  channel: any;
  user: any;
  clinicId: String;
  currentLineMessageCount: Number;
  lineMessageLimit: Number;
  setCurrentLineMessageCount: () => void;
};

const ChatForm: React.VFC<Props> = (props) => {
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const submit = () => {
    if (message == "") {
      alert("本文を入力してください");
    } else {
      if (props.currentLineMessageCount >= props.lineMessageLimit) {
        setShowModal(true);
      } else {
        props.channel.send_message(message, props.user.id, props.clinicId);
        setMessage("");
      }
    }
  };
  const keyDown = (e) => {
    if (e.keyCode === 13) {
      setMessage(e.target.value);
      //submit();
    }
  };
  return (
    <>
      <div className="input-group">
        <textarea
          name="message"
          placeholder="メッセージを入力"
          className="form-control"
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => keyDown(e)}
          value={message}
        />
        <span className="input-group-append">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => submit()}
          >
            <i className="fas fa-paper-plane" />
          </button>
        </span>
      </div>
      <PlanUpgradeModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={`プランのアップグレードが必要です`}
        text={`現在のプランのメセージ送信上限を超えました。以下のボタンからプランのアップグレードをお願いします`}
      />
    </>
  );
};
export default ChatForm;
