import React, { useState } from "react";

type Props = {
  message: any;
  clinicIcon: String;
};

const SentMessage: React.VFC<Props> = (props) => {
  return (
    <>
      <div className="direct-chat-msg right mb-3">
        <div className="direct-chat-infos clearfix">
          <span className="direct-chat-name float-right">クリニック</span>
          <span className="direct-chat-timestamp float-left">
            {props.message.elapsed}
          </span>
        </div>

        <img
          className="direct-chat-img"
          src={props.clinicIcon}
          alt="クリニック"
        />

        <div className="direct-chat-text p-3 sent">{props.message.body}</div>
      </div>
    </>
  );
};
export default SentMessage;
