import React, { useState, useCallback, useEffect, useRef } from "react";

type Props = {
  chatUser: any;
  setChatUser: () => void;
  updateUserLists: (user) => void;
};

import toast from "react-hot-toast";
import { updateChatUserInfo } from "../../libs/api/user";

const ChatUserInfo: React.VFC<Props> = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [actionStatus, setActionStatus] = useState(
    props.chatUser.action_status
  );
  const [userName, setUserName] = useState(props.chatUser.name);

  useEffect(() => {
    setIsEdit(false);
    setActionStatus(props.chatUser.action_status);
    setUserName(props.chatUser.name);
  }, [props.chatUser]);

  const updateUserName = async () => {
    let res = await updateChatUserInfo(props.chatUser.id, {
      overwrite_name: userName,
    });
    props.setChatUser((prevUser) => {
      prevUser.name = res.data.user?.name;
      return prevUser;
    });
    //リスト更新
    props.updateUserLists(res.data.user);
    toast.success("ユーザ名を変更しました");
    setIsEdit(false);
  };

  const updateStatusActionRequired = async () => {
    let res = await updateChatUserInfo(props.chatUser.id, {
      action_status: "action_required",
    });
    props.setChatUser((prevUser) => {
      prevUser.action_status = "action_required";
      return prevUser;
    });
    //リスト更新
    props.updateUserLists(res.data.user);
    toast.success("ステータスを「要対応」に変更しました");
    setActionStatus("action_required");
  };

  const updateStatusAcknowledged = async () => {
    let res = await updateChatUserInfo(props.chatUser.id, {
      action_status: "acknowledged",
    });
    props.setChatUser((prevUser) => {
      prevUser.action_status = "acknowledged";
      return prevUser;
    });
    //リスト更新
    props.updateUserLists(res.data.user);
    toast.success("ステータスを「対応済み」に変更しました");
    setActionStatus("acknowledged");
  };

  const updateStatusNull = async () => {
    let res = await updateChatUserInfo(props.chatUser.id, {
      action_status: null,
    });
    props.setChatUser((prevUser) => {
      prevUser.action_status = null;
      return prevUser;
    });
    //リスト更新
    props.updateUserLists(res.data.user);
    toast.success("ステータスを解除しました");
    setActionStatus(null);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <img
            className="direct-chat-img"
            src={props.chatUser.avatar_image}
            alt={userName}
          />
          {isEdit ? (
            <>
              <div className="ml-2">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  defaultValue={userName}
                  onChange={(e) => {
                    e.preventDefault();
                    setUserName(e.target.value);
                  }}
                />
              </div>
              <a
                className="ml-2 text-xs"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  updateUserName();
                }}
              >
                <span className="mr-1">変更する</span>
                <i className="fas fa-edit"></i>
              </a>
            </>
          ) : (
            <>
              <div className="ml-2">{userName}</div>
              <a
                className="ml-2 text-xs"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(true);
                }}
              >
                <span className="mr-1">編集</span>
                <i className="fas fa-edit"></i>
              </a>
            </>
          )}
        </div>
        <div className="d-flex align-items-center">
          {actionStatus == "action_required" ? (
            <>
              <button
                className="btn btn-primary mr-2 btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  updateStatusNull();
                }}
              >
                <i className="fas fa-comments mr-1"></i>
                要対応
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-default mr-2 btn-sm text-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  updateStatusActionRequired();
                }}
              >
                <i className="fas fa-comments mr-1"></i>
                要対応
              </button>
            </>
          )}

          {actionStatus == "acknowledged" ? (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  updateStatusNull();
                }}
              >
                <i className="fas fa-check mr-1"></i>
                対応済み
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-default btn-sm text-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  updateStatusAcknowledged();
                }}
              >
                <i className="fas fa-check mr-1"></i>
                対応済み
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ChatUserInfo;
