import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import ReactLoading from "react-loading";
import { uploadImage, updateImage } from "../../libs/api/image";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";

type Props = {
  image: any;
  setStep: () => void;
  mode: String;
};

const UsersFormImage: React.VFC<Props> = (props) => {
  const [previewFront, setPreviewFront] = useState(null);
  const [previewLeft, setPreviewLeft] = useState(null);
  const [previewRight, setPreviewRight] = useState(null);

  const [mode, setMode] = useState(props.mode);

  const [frontError, setFrontError] = useState(false);
  const [leftError, setLeftError] = useState(false);
  const [rightError, setRightError] = useState(false);

  const [frontLoading, setFrontLoading] = useState(false);
  const [leftLoading, setLeftLoading] = useState(false);
  const [rightLoading, setRightLoading] = useState(false);

  const [uploading, setUploading] = useState(false);

  const compressOptions = {
    // 0.2MB以下に圧縮する
    maxSizeMB: 0.2,
  };

  const onSubmit = async () => {
    let has_error = false;
    if (mode == "create") {
      if (previewFront == null) {
        setFrontError(true);
        has_error = true;
      } else {
        setFrontError(false);
      }
      if (previewLeft == null) {
        setLeftError(true);
        has_error = true;
      } else {
        setLeftError(false);
      }
      if (previewRight == null) {
        setRightError(true);
        has_error = true;
      } else {
        setRightError(false);
      }
    }
    if (!has_error) {
      setUploading(true);
      if (mode == "create") {
        let res = await uploadImage({
          face_front: previewFront,
          face_left: previewLeft,
          face_right: previewRight,
        });
        setUploading(false);
        if (res.data.actionUrl != null) {
          location.href = res.data.actionUrl;
        } else {
          props.setStep("step_complete");
        }
      } else {
        await updateImage({
          face_front: previewFront,
          face_left: previewLeft,
          face_right: previewRight,
        });
        toast.success("画像を更新しました");
        setUploading(false);
      }
    }
  };
  if (uploading) {
    return (
      <>
        <section className="flex justify-center items-center h-screen">
          <div>
            <ReactLoading
              type="spin"
              color="#ebc634"
              height="100px"
              width="100px"
              className="mx-auto"
            />
            <p className="text-center mt-3">ファイルをアップロードしています</p>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <Form>
        <div className="card">
          <div className="card-body">
            {frontLoading ? (
              <div className="bd-callout bd-callout-info text-center">
                <ReactLoading
                  type="spin"
                  color="#ebc634"
                  height="20px"
                  width="20px"
                  className="mx-auto"
                />
                ファイルを読込中です...
              </div>
            ) : (
              <Form.Group className="mb-3" controlId="usersFaceFront">
                {previewFront == null && (
                  <>
                    <Form.Label>顔正面写真</Form.Label>
                    <Form.Control
                      name="face_front_image"
                      type="file"
                      accept="image/*"
                      isInvalid={frontError}
                      onChange={async (e) => {
                        const files = e.target.files;
                        if (files.length > 0) {
                          //ここでファイル圧縮
                          setFrontLoading(true);
                          const file = await imageCompression(
                            files[0],
                            compressOptions
                          );
                          setFrontLoading(false);
                          var reader = new FileReader();
                          reader.onload = (e) => {
                            setPreviewFront(e.target.result);
                            setFrontError(false);
                          };
                          //ここでファイルを圧縮
                          reader.readAsDataURL(file);
                        } else {
                          setPreviewFront("");
                        }
                      }}
                    />
                  </>
                )}
                {frontError && (
                  <Form.Control.Feedback type="invalid">
                    選択してください
                  </Form.Control.Feedback>
                )}
                {previewFront != null && (
                  <Row className="justify-content-md-center mt-2">
                    <Col md="6">
                      <figcaption className="figure-caption">
                        <span className="me-2">プレビュー</span>
                        <a href="#" onClick={() => setPreviewFront(null)}>
                          変更
                        </a>
                      </figcaption>
                      <img src={previewFront} />
                    </Col>
                  </Row>
                )}
                {props.image.face_front != null && previewFront == null && (
                  <Row className="justify-content-md-center mt-2">
                    <Col md="6">
                      <figure className="figure">
                        <figcaption className="figure-caption">
                          設定中の画像
                        </figcaption>
                        <img src={props.image.face_front} />
                      </figure>
                    </Col>
                  </Row>
                )}
              </Form.Group>
            )}

            {leftLoading ? (
              <div className="bd-callout bd-callout-info text-center">
                <ReactLoading
                  type="spin"
                  color="#ebc634"
                  height="20px"
                  width="20px"
                  className="mx-auto"
                />
                ファイルを読込中です...
              </div>
            ) : (
              <Form.Group className="mb-3" controlId="usersFaceLeft">
                {previewLeft == null && (
                  <>
                    <Form.Label>顔斜め左写真</Form.Label>
                    <Form.Control
                      name="face_left_image"
                      type="file"
                      accept="image/*"
                      isInvalid={leftError}
                      onChange={async (e) => {
                        const files = e.target.files;
                        if (files.length > 0) {
                          //ここでファイル圧縮
                          setLeftLoading(true);
                          const file = await imageCompression(
                            files[0],
                            compressOptions
                          );
                          setLeftLoading(false);
                          var reader = new FileReader();
                          reader.onload = (e) => {
                            setPreviewLeft(e.target.result);
                            setLeftError(false);
                          };
                          reader.readAsDataURL(file);
                        } else {
                          setPreviewLeft("");
                        }
                      }}
                    />
                  </>
                )}
                {leftError && (
                  <Form.Control.Feedback type="invalid">
                    選択してください
                  </Form.Control.Feedback>
                )}
                {previewLeft != null && (
                  <Row className="justify-content-md-center mt-2">
                    <Col md="6">
                      <figcaption className="figure-caption">
                        <span className="me-2">プレビュー</span>
                        <a href="#" onClick={() => setPreviewLeft(null)}>
                          変更
                        </a>
                      </figcaption>
                      <img src={previewLeft} />
                    </Col>
                  </Row>
                )}
                {props.image.face_left != null && previewLeft == null && (
                  <Row className="justify-content-md-center mt-2">
                    <Col md="6">
                      <figure className="figure">
                        <figcaption className="figure-caption">
                          設定中の画像
                        </figcaption>
                        <img src={props.image.face_left} />
                      </figure>
                    </Col>
                  </Row>
                )}
              </Form.Group>
            )}

            {rightLoading ? (
              <div className="bd-callout bd-callout-info text-center">
                <ReactLoading
                  type="spin"
                  color="#ebc634"
                  height="20px"
                  width="20px"
                  className="mx-auto"
                />
                ファイルを読込中です...
              </div>
            ) : (
              <Form.Group className="mb-3" controlId="usersFaceRight">
                {previewRight == null && (
                  <>
                    <Form.Label>顔斜め右写真</Form.Label>
                    <Form.Control
                      name="face_right_image"
                      type="file"
                      accept="image/*"
                      isInvalid={rightError}
                      onChange={async (e) => {
                        const files = e.target.files;
                        if (files.length > 0) {
                          //ここでファイル圧縮
                          setRightLoading(true);
                          const file = await imageCompression(
                            files[0],
                            compressOptions
                          );
                          setRightLoading(false);
                          var reader = new FileReader();
                          reader.onload = (e) => {
                            setPreviewRight(e.target.result);
                            setRightError(false);
                          };
                          reader.readAsDataURL(file);
                        } else {
                          setPreviewRight("");
                        }
                      }}
                    />
                    {rightError && (
                      <Form.Control.Feedback type="invalid">
                        選択してください
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
                {previewRight != null && (
                  <Row className="justify-content-md-center mt-2">
                    <Col md="6">
                      <figcaption className="figure-caption">
                        <span className="me-2">プレビュー</span>
                        <a href="#" onClick={() => setPreviewRight(null)}>
                          変更
                        </a>
                      </figcaption>
                      <img src={previewRight} />
                    </Col>
                  </Row>
                )}
                {props.image.face_right != null && previewRight == null && (
                  <Row className="justify-content-md-center mt-2">
                    <Col md="6">
                      <figure className="figure">
                        <figcaption className="figure-caption">
                          設定中の画像
                        </figcaption>
                        <img src={props.image.face_right} />
                      </figure>
                    </Col>
                  </Row>
                )}
              </Form.Group>
            )}
          </div>
          <div className="card-footer">
            <Button
              variant="default"
              className="me-2"
              type="button"
              onClick={() => {
                if (mode == "create") {
                  props.setStep("step_basic");
                } else {
                  location.href = "/users";
                }
              }}
            >
              戻る
            </Button>
            <Button variant="primary" type="button" onClick={() => onSubmit()}>
              送信する
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default UsersFormImage;
