import axios from "axios";
const jsonpAdapter = require("axios-jsonp");
/**
 * カテゴリ一覧
 */
export const getAddressFromZipCode = async (postal_code) => {
  const res = await axios.get(
    `https://api.zipaddress.net/?zipcode=${postal_code}`,
    {
      adapter: jsonpAdapter,
    }
  );
  const response = res.data;
  return {
    prefecture: response.pref,
    city: response.city,
    town: response.town,
  };
};
