import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ReactStars from "react-rating-stars-component";

import { Form, Button, Row, Col } from "react-bootstrap";

import { createReview } from "../../../libs/api/reviews";
import MultiImages from "../../forms/MultiImages";

type Props = {
  review: [any];
  treatment_slug?: string;
  clinic_slug?: string;
};

const UserTreatments: React.VFC<Props> = (props) => {
  type FormState = {
    point: number;
    q1_point: number;
    q2_point: number;
    q3_point: number;
    q4_point: number;
    q5_point: number;
    q6_point: number;
    q1_text: string;
    q2_text: string;
    q3_text: string;
    q4_text: string;
    q5_text: string;
    q6_text: string;
    q7_text: string;
    q8_text: string;
    kind: string;
    review_images: any[];
    treatment_slug?: string;
    clinic_slug?: string;
  };

  const [images, setImages] = useState([]);

  const radioOptions = [
    { value: 5, label: "満足" },
    { value: 4, label: "やや満足" },
    { value: 3, label: "どちらともいえない" },
    { value: 2, label: "やや不満" },
    { value: 1, label: "不満" },
  ];

  const visitRadioOptions = [
    { value: 5, label: "思う" },
    { value: 4, label: "やや思う" },
    { value: 3, label: "どちらともいえない" },
    { value: 2, label: "あまり思わない" },
    { value: 1, label: "思わない" },
  ];

  const recommendRadioOptions = [
    { value: 5, label: "勧めたい" },
    { value: 4, label: "やや勧めたい" },
    { value: 3, label: "どちらともいえない" },
    { value: 2, label: "あまり勧めたくない" },
    { value: 1, label: "勧めたくない" },
  ];

  const [roopNum, setRoopNum] = useState(props.review.review_images.length);
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: {
      point: props.review.point,
      q1_point: props.review.q1_point,
      q2_point: props.review.q2_point,
      q3_point: props.review.q3_point,
      q4_point: props.review.q4_point,
      q5_point: props.review.q5_point,
      q6_point: props.review.q6_point,
      q1_text: props.review.q1_text,
      q2_text: props.review.q2_text,
      q3_text: props.review.q3_text,
      q4_text: props.review.q4_text,
      q5_text: props.review.q5_text,
      q6_text: props.review.q6_text,
      q7_text: props.review.q7_text,
      q8_text: props.review.q8_text,
      kind: "review",
      review_images: props.review.review_images,
      treatment_slug: props.treatment_slug,
      clinic_slug: props.clinic_slug,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(true);
    await createReview(data);
    if (props.treatment_slug != null) {
      location.href = `/users/treatments/${props.treatment_slug}/reviews/complete`;
    }
    if (props.clinic_slug != null) {
      location.href = `/clinics/${props.clinic_slug}/reviews/complete`;
    }
    setSubmitting(false);
  });

  useEffect(() => {
    if (roopNum == 0) {
      setRoopNum(1);
    }
  }, [props.review]);

  const ratingChanged = (newRating) => {
    setValue("point", newRating);
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <div className="card mt-2">
          <div className="card-body">
            <div className="mb-3">
              <label className="text optional" htmlFor="review_point">
                <span className="me-1">総合評価(タップしてください)</span>
              </label>
              <div className="d-flex align-item-center mt-2 fs-2">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={32}
                  activeColor="#ffd700"
                  value={getValues("point")}
                />
              </div>
            </div>

            <div className="mb-5">
              <MultiImages
                images={images}
                setImages={setImages}
                compress={0.2}
              />
              {images.map((image, i) => {
                return (
                  <>
                    <input
                      type="hidden"
                      {...register(`review_images.${i}.src`)}
                      value={image}
                    />
                  </>
                );
              })}
            </div>

            <div className="mb-3">
              <div className="form-group text optional review_q1">
                <label className="text optional" htmlFor="review_q1">
                  施術結果・効果に関してどのくらい満足していますか
                </label>
                <div
                  className={`mt-2 ${
                    errors.q1_point != null ? "is-invalid" : ""
                  }`}
                >
                  {radioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={`review_q1_${option.value}`}
                        name="q1_point"
                        value={option.value}
                        type="radio"
                        label={option.label}
                        isInvalid={errors.q1_point != null}
                        {...register("q1_point", {
                          required: "選択してください",
                        })}
                      />
                    );
                  })}
                </div>
                {errors.q1_point && (
                  <Form.Control.Feedback type="invalid" className="fw-bold">
                    {errors.q1_point.message}
                  </Form.Control.Feedback>
                )}
                <label className="text" htmlFor="q1_text">
                  その理由をお聞かせください
                </label>
                <Form.Control
                  name="q1_text"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("q1_text")}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group text optional review_q2">
                <label className="text optional" htmlFor="review_q2">
                  施術前の説明は十分でしたか？
                  <br />
                  治療方針の説明やダウンタイム、痛みに関しての説明は十分にありましたか？
                </label>
                <div
                  className={`mt-2 ${
                    errors.q2_point != null ? "is-invalid" : ""
                  }`}
                >
                  {radioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={`review_q2_${option.value}`}
                        name="q2_point"
                        value={option.value}
                        type="radio"
                        label={option.label}
                        isInvalid={errors.q2_point != null}
                        {...register("q2_point", {
                          required: "選択してください",
                        })}
                      />
                    );
                  })}
                </div>
                {errors.q2_point && (
                  <Form.Control.Feedback type="invalid" className="fw-bold">
                    {errors.q2_point.message}
                  </Form.Control.Feedback>
                )}
                <label className="text" htmlFor="q2_text">
                  その理由をお聞かせください
                </label>
                <Form.Control
                  name="q2_text"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("q2_text")}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group text optional review_q3">
                <label className="text optional" htmlFor="review_q3">
                  ドクター、スタッフの対応はいかがでしたか？
                </label>
                <div
                  className={`mt-2 ${
                    errors.q3_point != null ? "is-invalid" : ""
                  }`}
                >
                  {radioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={`review_q3_${option.value}`}
                        name="q3_point"
                        value={option.value}
                        type="radio"
                        label={option.label}
                        isInvalid={errors.q3_point != null}
                        {...register("q3_point", {
                          required: "選択してください",
                        })}
                      />
                    );
                  })}
                </div>
                {errors.q3_point && (
                  <Form.Control.Feedback type="invalid" className="fw-bold">
                    {errors.q3_point.message}
                  </Form.Control.Feedback>
                )}
                <label className="text" htmlFor="q3_text">
                  その理由をお聞かせください
                </label>
                <Form.Control
                  name="q3_text"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("q3_text")}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="form-group text optional review_q4">
                <label className="text optional" htmlFor="review_q4">
                  院内の雰囲気、設備、清潔感はいかがでしたか？
                </label>
                <div
                  className={`mt-2 ${
                    errors.q4_point != null ? "is-invalid" : ""
                  }`}
                >
                  {radioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={`review_q4_${option.value}`}
                        name="q4_point"
                        value={option.value}
                        type="radio"
                        label={option.label}
                        isInvalid={errors.q4_point != null}
                        {...register("q4_point", {
                          required: "選択してください",
                        })}
                      />
                    );
                  })}
                </div>
                {errors.q4_point && (
                  <Form.Control.Feedback type="invalid" className="fw-bold">
                    {errors.q4_point.message}
                  </Form.Control.Feedback>
                )}
                <label className="text" htmlFor="q4_text">
                  その理由をお聞かせください
                </label>
                <Form.Control
                  name="q4_text"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("q4_text")}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="form-group text optional review_q5">
                <label className="text optional" htmlFor="review_q5">
                  次回も来院したいと思いますか？
                </label>
                <div
                  className={`mt-2 ${
                    errors.q5_point != null ? "is-invalid" : ""
                  }`}
                >
                  {visitRadioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={`review_q5_${option.value}`}
                        name="q5_point"
                        value={option.value}
                        type="radio"
                        label={option.label}
                        isInvalid={errors.q5_point != null}
                        {...register("q5_point", {
                          required: "選択してください",
                        })}
                      />
                    );
                  })}
                </div>
                {errors.q5_point && (
                  <Form.Control.Feedback type="invalid" className="fw-bold">
                    {errors.q5_point.message}
                  </Form.Control.Feedback>
                )}
                <label className="text" htmlFor="q5_text">
                  その理由をお聞かせください
                </label>
                <Form.Control
                  name="q5_text"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("q5_text")}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="form-group text optional review_q6">
                <label className="text optional" htmlFor="review_q6">
                  親しい友人や家族に進めたいと思いますか？
                </label>
                <div
                  className={`mt-2 ${
                    errors.q6_point != null ? "is-invalid" : ""
                  }`}
                >
                  {recommendRadioOptions.map((option) => {
                    return (
                      <Form.Check
                        key={`review_q6_${option.value}`}
                        name="q6_point"
                        value={option.value}
                        type="radio"
                        label={option.label}
                        isInvalid={errors.q6_point != null}
                        {...register("q6_point", {
                          required: "選択してください",
                        })}
                      />
                    );
                  })}
                </div>
                {errors.q6_point && (
                  <Form.Control.Feedback type="invalid" className="fw-bold">
                    {errors.q6_point.message}
                  </Form.Control.Feedback>
                )}
                <label className="text" htmlFor="q6_text">
                  その理由をお聞かせください
                </label>
                <Form.Control
                  name="q6_text"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("q6_text")}
                />
              </div>
            </div>

            <div className="mb-3">
              <div className="form-group text optional review_q7">
                <label className="text optional" htmlFor="review_q7">
                  クリニックへのご意見、感想があればお聞かせください。
                </label>
                <Form.Control
                  name="q7_text"
                  as="textarea"
                  className="mt-2"
                  rows={10}
                  placeholder="自由記述欄"
                  {...register("q7_text")}
                />
              </div>
            </div>
          </div>
          <input
            name="treatment_slug"
            type="hidden"
            {...register("treatment_slug")}
          />
          <input name="kind" type="hidden" {...register("kind")} />
          <div className="card-footer">
            {submitting ? (
              <>
                <Button variant="primary" disabled type="button">
                  送信中...
                </Button>
              </>
            ) : (
              <>
                <Button variant="primary" type="submit">
                  送信する
                </Button>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};
export default UserTreatments;
