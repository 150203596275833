import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
const ToastError: React.VFC = ({
  showToast,
  setShowToast,
  message,
  position,
}) => {
  <ToastContainer className="p-3 position-fixed" position={position}>
    <Toast
      className="bg-danger"
      show={showToast}
      onClose={() => setShowToast(false)}
    >
      <Toast.Header>
        <strong className="me-auto text-danger">
          <i className="fas fa-check me-2" />
          成功
        </strong>
      </Toast.Header>
      <Toast.Body className="text-white">{message}</Toast.Body>
    </Toast>
  </ToastContainer>;
};
export default ToastError;
