import React, { useState } from "react";
import UserDetailModal from "../modals/user_treatments/UserDetail";
import FavoriteIcon from "./FavoriteIcon";
import dayjs from "dayjs";
type Props = {
  user_treatment: any;
  favoriteIds: [];
  setFavoriteIds: () => void;
  isClinic?: boolean;
};
const UserTreatmentList: React.VFC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);

  const [status, setStatus] = useState(props.user_treatment.status);
  const [rank, setRank] = useState(props.user_treatment.rank);
  const [faceType, setFaceType] = useState(props.user_treatment.user.face_type);

  const statusName = () => {
    if (status == "applied") {
      return "新規申し込み";
    } else if (status == "unreachable_by_phone") {
      return "TEL不通";
    } else if (status == "scheduling") {
      return "日程調整中";
    } else if (status == "online_date_fixed") {
      return "オンライン審査日程確定";
    } else if (status == "date_fixed") {
      return "来院予約日程確定";
    } else if (status == "operation_completed") {
      return "施術完了";
    } else if (status == "not_adopted") {
      return "不採用";
    } else if (status == "declined") {
      return "辞退";
    } else if (status == "unresponsive") {
      return "音信不通";
    } else if (status == "report_completed") {
      return "完了報告済み";
    }
  };
  const rankName = () => {
    if (rank == null) {
      return "--";
    } else if (rank == "a_rank") {
      return "Aランク";
    } else if (rank == "b_rank") {
      return "Bランク";
    } else if (rank == "c_rank") {
      return "Cランク";
    } else if (rank == "d_rank") {
      return "Dランク";
    }
  };
  const faceTypeName = () => {
    if (faceType == null) {
      return "--";
    } else if (faceType == "type1") {
      return "脂肪タイプ";
    } else if (faceType == "type2") {
      return "たるみタイプ";
    } else if (faceType == "type3") {
      return "小じわタイプ";
    } else if (faceType == "type4") {
      return "ボリュームロスタイプ";
    }
  };

  const statusTag = () => {
    return <span className="text-bold text-gray">{statusName()}</span>;
  };
  return (
    <>
      <div className="card" role="button">
        <div className="card-header p-2">
          <h3 className="card-title text-sm">
            {props.user_treatment.treatment.title}
          </h3>
        </div>
        <div
          className="card-body p-0 cursor-zoom-in position-relative"
          onClick={() => handleShow()}
        >
          <img
            src={props.user_treatment.main_image}
            key={props.user_treatment.id}
            className=""
          />
        </div>
        <div className="card-footer p-0">
          <ul className="nav flex-column">
            <li className="nav-item">
              <div className="nav-link p-2">
                {props.user_treatment.user.name}（
                {props.user_treatment.user.age}）
                {props.user_treatment.user.gender == "male" ? (
                  <span className="float-right badge bg-primary">男性</span>
                ) : (
                  <span className="float-right badge bg-danger">女性</span>
                )}
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link p-2">
                <span>{statusTag()}</span>
                <FavoriteIcon
                  id={props.user_treatment.id}
                  favoriteIds={props.favoriteIds}
                  setFavoriteIds={props.setFavoriteIds}
                />
              </div>
            </li>
            {(props.user_treatment.web_exam_date != null ||
              props.user_treatment.exam_date != null) && (
              <li className="nav-item text-gray">
                <div className="nav-link p-2">
                  {props.user_treatment.web_exam_date != null && (
                    <>
                      <div className="d-flex justify-content-between">
                        <label>WEB日程: </label>
                        <span>
                          {dayjs(props.user_treatment.web_exam_date).format(
                            "YYYY/MM/DD HH:mm"
                          )}
                        </span>
                      </div>
                    </>
                  )}
                  {props.user_treatment.exam_date != null && (
                    <>
                      <div className="d-flex justify-content-between">
                        <label className="mb-0">診察日程: </label>
                        <span>
                          {dayjs(props.user_treatment.exam_date).format(
                            "YYYY/MM/DD HH:mm"
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      <UserDetailModal
        id={props.user_treatment.id}
        showModal={showModal}
        setShowModal={setShowModal}
        user={props.user_treatment.user}
        treatCompletedAt={props.user_treatment.treat_completed_at}
        visitedAt={props.user_treatment.visited_at}
        liquidatedAt={props.user_treatment.liquidated_at}
        status={status}
        setStatus={setStatus}
        rank={rank}
        setRank={setRank}
        faceType={faceType}
        setFaceType={setFaceType}
        statusName={statusName}
        rankName={rankName}
        faceTypeName={faceTypeName}
        examDate={props.user_treatment.exam_date}
        webExamDate={props.user_treatment.web_exam_date}
        isClinic={props.isClinic}
        reviewPoint={props.user_treatment.review_point}
        reviewComment={props.user_treatment.review_comment}
      />
    </>
  );
};
export default UserTreatmentList;
