import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const FullImage: React.VFC = ({ showModal, setShowModal, imageUrl }) => {
  const handleClose = () => setShowModal(false);
  return (
    <Modal size={`lg`} show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>画像詳細</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <img src={imageUrl} className="mw-100 ml-auto" />
      </Modal.Body>
    </Modal>
  );
};
export default FullImage;
