import React, { useState } from "react";
import SignInModal from "../modals/ChatSignIn";
import ApplyChatModal from "../modals/ApplyChat";
const TreatmentChat: React.VFC = ({ user, clinic, signInUrl }) => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const modal = () => {
    if (user == null) {
      return (
        <SignInModal
          showModal={showModal}
          setShowModal={setShowModal}
          url={signInUrl}
        />
      );
    } else {
      return (
        <ApplyChatModal
          showModal={showModal}
          setShowModal={setShowModal}
          clinic={clinic}
        />
      );
    }
  };

  return (
    <>
      <div className="d-grid col-md-6 ms-auto me-auto col-12">
        <button className="btn btn-primary btn-lg" onClick={handleShow}>
          <i className="fas fa-angle-right me-2"></i>チャットで相談する
        </button>
      </div>
      {modal()}
    </>
  );
};
export default TreatmentChat;
