import getClient from "./client";

/**
 * 自身の情報を更新
 */
export const updateSelfUser = async (params) => {
  return getClient().post(`users/update_self`, params);
};

/**
 * 自身の情報を更新
 */
export const updateSelfUserFromEvent = async (params) => {
  return getClient().post(`users/update_self_from_event`, params);
};

/**
 * 自身の情報を更新
 */
export const updateSelfUserFromChat = async (params) => {
  return getClient().post(`users/update_self_from_chat`, params);
};

/**
 * 情報を更新
 */
export const updateUser = async (id, params) => {
  return getClient().put(`users/${id}`, { user: params });
};

/**
 * チャットユーザから情報を更新
 */
export const updateChatUserInfo = async (id, params) => {
  return getClient().put(`users/${id}/update_chat_user_info`, { user: params });
};

/**
 * チャットユーザの検索
 */
export const searchLineMessageUsers = async (name) => {
  return getClient().get(`users/search_line_message_users`, {
    params: { name: name },
  });
};

/**
 * 最新のトーク履歴を取得
 */
export const getLatestMessage = async (id, clinicId, page = null) => {
  return getClient().get(`users/${id}/latest_message`, {
    params: { clinic_id: clinicId, page: page },
  });
};

/**
 * current_userの最新のトーク履歴を取得
 */
export const getSelfLatestMessage = async (clinicId) => {
  return getClient().get(`users/self_latest_message.json`, {
    params: { clinic_id: clinicId },
  });
};
