import React, { useState, useEffect, useRef } from "react";

import ChatForm from "./ChatForm";
import Message from "./Message";

import { ActionCableConsumer } from "react-actioncable-provider";

import { getSelfLatestMessage } from "../../../libs/api/user";

type Props = {
  currentUser: any;
  clinic: any;
  setClinic: () => void;
  channel: any;
  uid: String;
  clinicId: String;
  clinicIcon: String;
};

const Chat: React.VFC<Props> = (props) => {
  const messageEl = useRef(null);
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    getSelfLatestMessage(props.clinicId).then((res) => {
      setMessages(res.data.messages);
    });
  }, [props.currentUser]);

  useEffect(() => {
    if (messageEl) {
      messageEl.current.scroll({
        top: messageEl.current.scrollHeight,
      });
    }
  }, [messages]);

  const handleReceived = (line_message) => {
    console.log("handleReceived", line_message);
    if (props.currentUser.id == line_message.user_id) {
      setMessages((prevMessages) => [...prevMessages, line_message]);
    }
  };

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: "LineMessageChannel", uid: props.uid }}
        onReceived={handleReceived}
      >
        <div className="card">
          <div className="card-header">{props.clinic.name}</div>
          <div className="card-body">
            <div className="direct-chat-messages" ref={messageEl}>
              {messages.map((message) => (
                <Message
                  message={message}
                  key={message.id}
                  user={props.currentUser}
                  clinicIcon={props.clinicIcon}
                ></Message>
              ))}
            </div>
          </div>
          <div className="card-footer">
            <ChatForm
              channel={props.channel}
              user={props.currentUser}
              clinicId={props.clinicId}
            ></ChatForm>
          </div>
        </div>
      </ActionCableConsumer>
    </>
  );
};
export default Chat;
