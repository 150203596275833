import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ApplyChat: React.VFC = ({ showModal, setShowModal, clinic }) => {
  const handleClose = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>相談を開始します。よろしいでしょうか？</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid col-md-6 col-12 ms-auto me-auto">
          <a
            href={`/messages/${clinic.slug}`}
            className="btn btn-primary btn-lg"
          >
            相談する
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ApplyChat;
