
import Rails from "@rails/ujs";
//import * as ActiveStorage from "@rails/activestorage";
//import "channels";

Rails.start();
//ActiveStorage.start();

import "bootstrap";
import "../stylesheets/application";
import "./lib/util";

//react
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
