import React, { useState } from "react";
import { addFavorite, removeFavorite } from "../../libs/api/favorites";
import toast from "react-hot-toast";

const FavoriteIcon: React.VFC = ({ id, favoriteIds, setFavoriteIds }) => {
  const isActive = () => {
    return favoriteIds.includes(id);
  };
  const remove = async () => {
    const r = await removeFavorite(id);
    setFavoriteIds(r.data);
    toast.success("お気に入りから削除しました");
  };
  const add = async () => {
    const r = await addFavorite(id);
    setFavoriteIds(r.data);
    toast.success("お気に入りに追加しました");
  };
  return (
    <>
      {isActive() ? (
        <>
          <span className="float-right">
            <i
              className="fas fa-heart favorite-icon active"
              onClick={() => remove()}
            ></i>
          </span>
        </>
      ) : (
        <>
          <span className="float-right">
            <i
              className="fas fa-heart favorite-icon cursor-pointer"
              onClick={() => add()}
            ></i>
          </span>
        </>
      )}
    </>
  );
};
export default FavoriteIcon;
