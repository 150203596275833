import getClient from "./client";

/**
 * 自身の情報を更新
 */
export const addFavorite = async (user_treatment_id) => {
  return getClient().post(`favorites/add`, { id: user_treatment_id });
};

/**
 * 情報を更新
 */
export const removeFavorite = async (user_treatment_id) => {
  return getClient().post(`favorites/remove`, { id: user_treatment_id });
};
