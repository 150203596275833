import getClient from "./client";

/**
 * 情報を更新
 */
export const updateUserTreatment = async (id, params) => {
  return getClient().put(`user_treatments/${id}`, {
    user_treatment: params,
  });
};

/**
 * 独立ステータスの更新
 */
export const updateUserTreatmentDateStatus = async (
  id,
  treatCompleted,
  visited,
  liquidated
) => {
  var params = { treat_completed: null, visited: null, liquidated: null };
  if (visited) {
    params.visited = 1;
  }
  if (liquidated) {
    params.liquidated = 1;
  }
  if (treatCompleted) {
    params.treat_completed = 1;
  }
  return getClient().put(`user_treatments/${id}/udpdate_date_status`, {
    user_treatment: params,
  });
};
