import React from "react";
import { Modal } from "react-bootstrap";

const ChatSignIn: React.VFC = ({ showModal, setShowModal, url }) => {
  const handleClose = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>チャットで相談には会員登録が必要です</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid col-md-6 col-12 ms-auto me-auto">
          <a href={url} data-method="post" className="btn bg-line btn-lg">
            <i className="fab fa-line me-2"></i>
            LINEで登録して相談する
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ChatSignIn;
