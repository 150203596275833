import React, { useState } from "react";
import { updateUserTreatment } from "../../../libs/api/user_treatment";
import toast from "react-hot-toast";
import dayjs from "dayjs";

type Props = {
  id: string;
  examDate: Date;
  setExamDate: (v: Date) => void;
  webExamDate: Date;
  setWebExamDate: (v: Date) => void;
  editExamDate: boolean;
  setEditExamDate: (v: boolean) => void;
  status: string;
  setStatus: (v: string) => void;
};

const EditExamDateForm: React.VFC<Props> = (props) => {
  const [examDate, setExamDate] = useState(props.examDate);
  const [webExamDate, setWebExamDate] = useState(props.webExamDate);

  const submit = async () => {
    const r = await updateUserTreatment(props.id, {
      exam_date: examDate,
      web_exam_date: webExamDate,
    });
    props.setExamDate(r.data.user_treatment.exam_date);
    props.setWebExamDate(r.data.user_treatment.web_exam_date);
    props.setStatus("date_fixed");
    toast.success("診察日程を更新しました");
    props.setEditExamDate(false);
  };

  const defaultValue = () => {
    if (props.examDate != null) {
      return dayjs(props.examDate).format("YYYY-MM-DDTHH:mm");
    } else {
      return null;
    }
  };

  const defaultWebValue = () => {
    if (props.webExamDate != null) {
      return dayjs(props.webExamDate).format("YYYY-MM-DDTHH:mm");
    } else {
      return null;
    }
  };

  return (
    <div className="mt-1 mb-2">
      {props.editExamDate ? (
        <>
          <label>オンライン審査日程</label>
          <div className="input-group">
            <input
              type="datetime-local"
              className="form-control form-control-sm"
              onChange={(e) => setWebExamDate(e.target.value)}
              defaultValue={defaultWebValue()}
            />
          </div>

          <label className="mt-2">診察日程</label>
          <div className="input-group">
            <input
              type="datetime-local"
              className="form-control form-control-sm"
              onChange={(e) => setExamDate(e.target.value)}
              defaultValue={defaultValue()}
            />
          </div>
          <div className="d-flex align-items-center mt-3 justify-content-between">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => submit()}
            >
              更新
            </button>
            <div className="text-right mt-2 ml-2">
              <span
                className="color-link cursor-pointer"
                onClick={() => props.setEditExamDate(false)}
              >
                <i className="fas fa-times mr-1"></i>キャンセル
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-75">
            {[
              "online_date_fixed",
              "date_fixed",
              "operation_completed",
              "report_completed",
            ].includes(props.status) && (
              <div className="d-flex justify-content-between">
                <label>オンライン審査日程: </label>
                <span>
                  {props.webExamDate != null ? (
                    dayjs(props.webExamDate).format("YYYY/MM/DD HH:mm")
                  ) : (
                    <>--</>
                  )}
                </span>
              </div>
            )}
            {["date_fixed", "operation_completed", "report_completed"].includes(
              props.status
            ) && (
              <div className="d-flex justify-content-between">
                <label>診察日程:</label>
                <span>
                  {props.examDate != null ? (
                    dayjs(props.examDate).format("YYYY/MM/DD HH:mm")
                  ) : (
                    <>--</>
                  )}
                </span>
              </div>
            )}
          </div>
          {[
            "online_date_fixed",
            "date_fixed",
            "operation_completed",
            "report_completed",
          ].includes(props.status) && (
            <div
              className="color-link cursor-pointer w-25 text-right"
              onClick={() => props.setEditExamDate(true)}
            >
              <i className="fas fa-edit mr-1"></i>編集
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default EditExamDateForm;
