import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const NewMonitoImage: React.VFC = ({ showModal, setShowModal, url }) => {
  const handleClose = () => setShowModal(false);
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>施術の応募には事前に写真登録が必要です</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid col-md-6 col-12 ms-auto me-auto">
          <a href={url} className="btn btn-primary btn-lg">
            写真登録を開始する
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default NewMonitoImage;
