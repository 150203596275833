import React, { useEffect, useState } from "react";
import FormProfile from "./FormProfile";
import { Toaster } from "react-hot-toast";
const UpdateMonitorProfile: React.VFC = ({ user, image }) => {
  const [step, setStep] = useState("step_basic");
  useEffect(() => {
    setStep(user.form_step);
  }, [user]);
  return (
    <>
      <div className="p-2 pt-4">
        <FormProfile setStep={setStep} user={user} mode={`update`} />
      </div>
      <Toaster />
    </>
  );
};
export default UpdateMonitorProfile;
