import React, { useState } from "react";

type Props = {
  users: [any];
  setUsers: () => void;
  setChatUser: () => void;
};

import UserList from "./UserList";

const LineMessagesUserLists: React.VFC<Props> = (props) => {
  return (
    <>
      <div className="w-100 user-lists-wrap">
        {props.users.map((user) => {
          return (
            <UserList
              user={user}
              key={user.id}
              setChatUser={props.setChatUser}
            ></UserList>
          );
        })}
      </div>
    </>
  );
};
export default LineMessagesUserLists;
