import React, { useState } from "react";

const UsersFromThanks: React.VFC = ({ step }) => {
  return (
    <>
      <div className="bd-callout bd-callout-info">
        モニターの応募が完了しました。ありがとうございます
      </div>
    </>
  );
};
export default UsersFromThanks;
