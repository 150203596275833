import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";

type Props = {
  num: number;
};

const ReadonlyStar: React.VFC<Props> = (props) => {
  return (
    <>
      <ReactStars
        count={5}
        size={18}
        activeColor="#ffd700"
        edit={false}
        value={props.num}
      />
    </>
  );
};
export default ReadonlyStar;
