import React, { useState, useCallback, useEffect, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";

import ChatForm from "./ChatForm";
import ChatUserInfo from "./ChatUserInfo";
import Message from "./Message";

import { getLatestMessage } from "../../libs/api/user";

import toast from "react-hot-toast";

type Props = {
  chatUser: any;
  setChatUser: () => void;
  setUsers: () => void;
  updateUserLists: (user) => void;
  channel: any;
  uid: String;
  clinicId: String;
  currentLineMessageCount: Number;
  lineMessageLimit: Number;
  setCurrentLineMessageCount: () => void;
  messages: [any];
  setMessages: () => void;
  clinicIcon: String;
};

const Chat: React.VFC<Props> = (props) => {
  const messageEl = useRef(null);

  const [loadingMessage, setLoadingMessage] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleScroll = () => {
    if (messageEl.current.scrollTop == 0) {
      if (loadingMessage == false && !loadAll) {
        setLoadingMessage(true);
        //apiでmessage取得
        getLatestMessage(
          props.chatUser.id,
          props.clinicId,
          currentPage + 1
        ).then((res) => {
          if (res.data.messages.length < 20) {
            if (loadAll == false) {
              setLoadAll(true);
              toast.success("最初まで読み込みました");
            }
          }
          const newMessages = res.data.messages.filter((msg) => {
            return !props.messages
              .map((prevMsg) => prevMsg.id)
              .includes(msg.id);
          });

          props.setMessages((prevMessages) => [
            ...newMessages,
            ...prevMessages,
          ]);
          setCurrentPage((v) => {
            return v + 1;
          });
          setTimeout(() => {
            setLoadingMessage(false);
          }, 100);
        });
      }
    }
  };

  useEffect(() => {
    setLoadAll(false);
    setCurrentPage(1);
    getLatestMessage(props.chatUser.id, props.clinicId).then((res) => {
      props.setMessages(res.data.messages);
      if (res.data.messages.length < 20) {
        setLoadAll(true);
      }
    });
  }, [props.chatUser]);

  useEffect(() => {
    if (messageEl && !loadingMessage) {
      messageEl.current.scroll({
        top: messageEl.current.scrollHeight,
      });
    }
  }, [props.messages]);

  return (
    <>
      <div className="pb-3 pl-3 pr-3">
        <div className="card">
          <div className="card-header p-2">
            <ChatUserInfo
              chatUser={props.chatUser}
              setChatUser={props.setChatUser}
              updateUserLists={props.updateUserLists}
            />
          </div>
          <div className="card-body">
            <div
              className="direct-chat-messages"
              ref={messageEl}
              onScroll={handleScroll}
            >
              {loadingMessage && !loadAll && (
                <>
                  <div className="text-center">
                    <Spinner animation="border" size="sm" className="mr-2" />
                    <span>loading...</span>
                  </div>
                </>
              )}
              {props.messages.map((message) => (
                <Message
                  message={message}
                  key={message.id}
                  user={props.chatUser}
                  clinicIcon={props.clinicIcon}
                ></Message>
              ))}
            </div>
          </div>
          <div className="card-footer">
            <ChatForm
              channel={props.channel}
              user={props.chatUser}
              clinicId={props.clinicId}
              currentLineMessageCount={props.currentLineMessageCount}
              setCurrentLineMessageCount={props.setCurrentLineMessageCount}
              lineMessageLimit={props.lineMessageLimit}
            ></ChatForm>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chat;
