import React, { useEffect, useState } from "react";
import FormImage from "./FormImage";
import { Toaster } from "react-hot-toast";
const UpdateMonitorImage: React.VFC = ({ user, image }) => {
  const [step, setStep] = useState("step_basic");
  useEffect(() => {
    setStep(user.form_step);
  }, [user]);
  return (
    <>
      <div className="p-2">
        <p className="mb-1">
          お顔の状態が分かるよう、ノーメイクの状態で、正面・斜め左右のお写真合計3枚を、
          加工アプリを使わずに撮影しアップロードしてください。
        </p>
        <p>
          肌状態が良くわかるお写真だと選定しやすいため、当確しやすくなります。
        </p>

        <FormImage setStep={setStep} image={image} mode={`update`} />
      </div>
      <Toaster />
    </>
  );
};
export default UpdateMonitorImage;
