import React, { useState } from "react";

type Props = {
  channel: any;
  user: any;
  clinicId: String;
};

const ChatForm: React.VFC<Props> = (props) => {
  const [message, setMessage] = useState("");
  const submit = () => {
    if (message == "") {
      alert("本文を入力してください");
    } else {
      props.channel.send_user_message(message, props.user.id, props.clinicId);
      setMessage("");
    }
  };
  const keyDown = (e) => {
    if (e.keyCode === 13) {
      setMessage(e.target.value);
      submit();
    }
  };
  return (
    <>
      <div className="input-group">
        <input
          type="text"
          name="message"
          placeholder="メッセージを入力"
          className="form-control"
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => keyDown(e)}
          value={message}
        />
        <span className="input-group-append">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => submit()}
          >
            送信
          </button>
        </span>
      </div>
    </>
  );
};
export default ChatForm;
